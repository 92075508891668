import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setActiveCarouselManager, setKeyHandler } from "Services/redux/app/actions";
import WithKeyHandler from 'HOC/WithKeyHandler';
import useFunction from 'hooks/useFunction';
import usePopup from 'hooks/usePopup';

//components
import CarouselManager from '../carousel/CarouselManager';
import AdsMessage from './components/adUI/AdsMessage';
import VideoControls from './controls/VideoControls';
import Watermark from './components/UI/Watermark';
import Article from './components/videoActions/article/Article';
import StreamLoader from './components/streamLoader/StreamLoader';
import PauseScreen from './components/pauseScreen/PauseScreen';
import VideoErrorPopup from 'components/popups/videoError/VideoErrorPopup';

// services
import { KeyHandlerCode } from 'data/constants';
import { CarouselManagerTypes } from 'data/constants';
import { keyCodes } from 'data/keyCodes';
import DeviceCenter from 'Services/platforms/deviceCenter';
import PlayerInterface from '../../Services/Player/playersCenter';
import Analytics from "Services/analytics/globalAnaltyics"
import ChannelSettings from 'utils/channelSettings/ChannelSettings';
import { setLocalStorage } from 'utils/storage/localStorage';
import { addVideoToCarousel, removeVideoFromCarousel } from 'Services/redux/entities/actions';
import { setVideoVisibility, setVideo } from 'Services/redux/video/actions';
import { hasInternet } from 'utils/utiliesFunctions';

//styles
import './videoPlayer.css';
import './castifyPlayer.css';

let playerUIAnimationTimeout, waterMarkTimeout, pauseScreenTimeout;

function VideoPlayer (props) {

	const dispatch = useDispatch();

	// is online 
	const [online, setOnline] = useState(false)

	const setIsOnline = () => {
		setOnline(true)
	}

	const setIsOffline = () => {
		setOnline(false)
	}

	useEffect(() => {

		window.addEventListener('online', setIsOnline)
		window.addEventListener('offline', setIsOffline)

		return () => {
			window.removeEventListener('online', setIsOnline)
			window.removeEventListener('offline', setIsOffline)
		}

	}, [])

	const { watermarkTime, progressBarTimeout, selectedVideo, videoPlayerContent, keyHandlerFromPlayer } =
		useSelector(({ app, entities, video }) => ({
			watermarkTime: app.graphic.watermark_player_permanent * 1000,
			progressBarTimeout: app.graphic.progressBarTimeout * 1000,
			selectedVideo: entities.videos[video.selectedVideo],
			videoPlayerContent: video.videoPlayerContent,
			keyHandlerFromPlayer: video.keyHandlerBeforePlayer
		}), shallowEqual);

	const [displayArticle, setDisplayArticle] = useState(false);
	const [isPlayingAds, setIsPlayingAds] = useState(false);
	const [displayWaterMark, setDisplayWaterMark] = useState(true);
	const [videoHasInit, setVideoHasInit] = useState(false);

	const { displayPopup } = usePopup();

	const playerRef = useRef();
	const playerUIRef = useRef();
	const prevPosRef = useRef();
	const loaderRef = useRef();
	const pauseScreenRef = useRef();

	useEffect(() => {
		let playr = document.getElementById('castifyPlayer');
		if (online && playr) {

			let time = PlayerInterface.playerInstance.getCurrentTime();
			playr.src = selectedVideo.streamURL;
			PlayerInterface.playerInstance.seekTo(time);
			PlayerInterface.playerInstance.play()
		}
	}, [online])

	useEffect(() => {
		const uiRef = playerRef.current;

		if (!hasInternet()) {
			exitVideo(true);
		}

		prevPosRef.current.focus();
		props.onMounted();
		initVideo();

		// register a listener so when ever a platform emitting toggleCaptions run the callback inside (the event will return if cc is on or not)
		const ccToggleEvent = ccState => PlayerInterface.action("toggleTracks", ccState);

		const onVisibilityChanged = () => {
			if (window.settings.platform === "emulator") return;

			// if the document is hidden pause the video
			if (document.hidden) {
				PlayerInterface.controller("pause");
				DeviceCenter.onDocumentHide();
			} else {
				console.log('visible ');
				setTimeout(() => {
					PlayerInterface.controller("play");
				}, 500)
			}
		};

		document.addEventListener("keydown", playerKeyHandler);
		document.addEventListener("mousemove", startPlayerUIAnimation);

		// register events
		document.addEventListener("visibilitychange", onVisibilityChanged);
		DeviceCenter.registerPlatformEvent("toggleCaptions", ccToggleEvent);

		return () => {
			clearTimeout(playerUIAnimationTimeout);
			clearTimeout(waterMarkTimeout);
			clearTimeout(pauseScreenTimeout);
			PlayerInterface.playerInstance.cleanup();
			document.removeEventListener("keydown", playerKeyHandler);
			document.removeEventListener("mousemove", startPlayerUIAnimation);
			document.removeEventListener("visibilitychange", onVisibilityChanged);
			DeviceCenter.removePlatformEvent("toggleCaptions", ccToggleEvent);
			uiRef.removeEventListener("transitionend", onUITransitionEnd);
			dispatch(setActiveCarouselManager());
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {

		// get video object and change the source
		const isSameVideo = PlayerInterface.action("changeStream", selectedVideo);

		if (isSameVideo) return;

		// initVideo(); // added "version": "5.6.9" Show preroll add every next video.

		const videosPlugins = ChannelSettings.getPlugins("video");

		for (const plugin of videosPlugins) {
			plugin.videoChanged && plugin.videoChanged(selectedVideo);
		}

	}, [selectedVideo]);

	const playerKeyHandler = useCallback((event) => {

		if (document.querySelector('.brd_consent')) {

			if (event.keyCode == 39 || event.keyCode == 37) {
				let index = event.keyCode == 39 ? 1 : 0;
				document.querySelector('.brd_consent').querySelectorAll("button")[index].focus()
			}

			return

		}

		const isControlsHide = !playerUIRef.current.classList.contains("showControls");

		if (event.keyCode == keyCodes.BACK) {
			event.preventDefault();
			event.stopPropagation();
			return isControlsHide ? exitVideo(false) : displayControlsUI(false, true);
		}

		if (isControlsHide) {
			event.preventDefault();
			event.stopPropagation();
			startPlayerUIAnimation();

			switch (event.keyCode) {
				case keyCodes.PLAY:
					return PlayerInterface.controller("play");
				case keyCodes.PAUSE:
					return PlayerInterface.controller("pause");
				case keyCodes.ENTER:
				case keyCodes.TOGGLE_PLAY:
					return PlayerInterface.controller("togglePlay");
			}

			return;
		}

		switch (event.keyCode) {
			case keyCodes.STOP:
				PlayerInterface.controller("stop");
				startPlayerUIAnimation();
				break;
			case keyCodes.PLAY:
				PlayerInterface.controller("play");
				startPlayerUIAnimation();
				break;
			case keyCodes.PAUSE:
				PlayerInterface.controller("pause");
				startPlayerUIAnimation();
				break;
			case keyCodes.ENTER:
				// PlayerInterface.controller("togglePlay");
				startPlayerUIAnimation();
				break;
			case keyCodes.TOGGLE_PLAY:
				PlayerInterface.controller("togglePlay");
				startPlayerUIAnimation();
				break;
			case keyCodes.LEFT:
			case keyCodes.RIGHT:
			case keyCodes.UP:
			case keyCodes.DOWN:
			case keyCodes.FORWARD:
			case keyCodes.REWIND:
				startPlayerUIAnimation(); break;
			default: isControlsHide && event.stopPropagation(); break;
		};


		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const initVideo = () => {

		const events = {
			loadeddata: () => {
				//if time=0 watermark will always be visible.
				if (watermarkTime > 0)  // display for time seconds
					waterMarkTimeout = setTimeout(() => setDisplayWaterMark(false), watermarkTime);
				else if (watermarkTime < 0) // don't display at all
					setDisplayWaterMark(false);
			},
			error: (e) => {
				console.log(e, 'video error aa');
				exitVideo(true); // go to prev page
				dispatch(setKeyHandler("exitPlayer"));
			},
			playing: () => {
				clearTimeout(pauseScreenTimeout);
				pauseScreenRef.current && pauseScreenRef.current.classList.remove("pauseScreen-visible");
			},
			pause: startPauseScreenAnimation
		};

		let [brighData] = ChannelSettings.getPlugins("brighData");

		PlayerInterface.init({
			type: selectedVideo.content_type || "video",
			target: playerRef.current,
			video: selectedVideo,
			ads: props.ads,
			events,
			brighData,
			callbacks: {
				onInit: (video) => {
					dispatch(setKeyHandler(KeyHandlerCode.CONTROLS));	// set where to put the focus - controls
					setVideoHasInit(true);

					playerUIRef.current.addEventListener("transitionend", onUITransitionEnd);

					const videosPlugins = ChannelSettings.getPlugins("video");

					for (const plugin of videosPlugins) {
						plugin.init && plugin.init(video);
					}

				},
				adStarts: () => {
					// when ads starts remove events adn display the ads UI

					document.removeEventListener("mousemove", startPlayerUIAnimation);
					document.removeEventListener("keydown", playerKeyHandler);
					setIsPlayingAds(true);
					displayControlsUI(false);
					dispatch(setKeyHandler("ads"));
				},
				adFinished: () => {
					document.addEventListener("mousemove", startPlayerUIAnimation);
					document.addEventListener("keydown", playerKeyHandler);
					setIsPlayingAds(false);
					dispatch(setKeyHandler(KeyHandlerCode.CONTROLS));
				},
				adError: () => {
					// in case an ad failed make sure we don't have duplicates events by first removing them and then add them again
					document.removeEventListener("mousemove", startPlayerUIAnimation);
					document.removeEventListener("keydown", playerKeyHandler);

					document.addEventListener("mousemove", startPlayerUIAnimation);
					document.addEventListener("keydown", playerKeyHandler);

					setIsPlayingAds(false);
				}
			},
			device: window.settings.platform,
			loader: loaderRef.current,
			analytics: Analytics,
			settings: {
				encoding: window.settings.platformSettings.encoding,
				subtitles: window.settings.platformSettings.subtitles
			}
		});

	}

	const setContent = useCallback((video, carouselId) => {
		dispatch(setVideo({ video, carouselId }));
	}, [dispatch])

	/**
	 * Exit video
	 * 
	 * @param {boolean} isError This function called due to error
	 */
	const exitVideo = useCallback(isError => {

		// if (homeScreen <= 0)
		// 	return displayExitPopup();

		// add the video to continue watching but not on error
		if (window.clientPlaylists.continueWatching && !isError)
			updateVideoToContinueWatching();

		if (isError) {
			const errorType = !navigator.onLine ? "no_connection" : "stream_issue"
			displayPopup(VideoErrorPopup, {
				type: errorType,
				goToKeyhandler: keyHandlerFromPlayer
			});
		} else {
			dispatch(setKeyHandler(keyHandlerFromPlayer));
		}

		props.onClose();
		// this will calose the player component
		dispatch(setVideoVisibility(false));

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateVideoToContinueWatching = () => {

		const activeVideo = PlayerInterface.action("getVideo");

		// do not add live stream to continue watching list
		if (activeVideo.is_live_streaming) return;

		const duration = PlayerInterface.action("getDuration");
		const currentTime = PlayerInterface.action("getCurrentTime");
		const shouldAddToWatchList = currentTime > 5 && currentTime < duration - 10;

		if (shouldAddToWatchList) {
			const newTime = (currentTime - 3).toFixed(2);
			dispatch(addVideoToCarousel("continueWatching", activeVideo.id, newTime));
			setLocalStorage({
				type: "continueWatching/add",
				value: { [activeVideo.id]: newTime }
			})
		} else {
			dispatch(removeVideoFromCarousel("continueWatching", activeVideo.id));

			setLocalStorage({
				type: "continueWatching/remove",
				value: activeVideo.id
			})
		}
	}

	// const displayExitPopup = () => {
	// 	playerUIRef.current.removeEventListener("transitionend", onUITransitionEnd);
	// 	document.removeEventListener("mousemove", startPlayerUIAnimation);
	// 	document.removeEventListener("keydown", playerKeyHandler);

	// 	PlayerInterface.controller("pause");

	// 	displayPopup(ExitPopUp, {
	// 		onClose: () => {
	// 			startPlayerUIAnimation();
	// 			document.addEventListener("mousemove", startPlayerUIAnimation);
	// 			document.addEventListener("keydown", playerKeyHandler);
	// 			playerUIRef.current.addEventListener("transitionend", onUITransitionEnd);
	// 		}
	// 	})
	// }

	const startPauseScreenAnimation = () => {
		clearTimeout(pauseScreenTimeout);

		pauseScreenTimeout = setTimeout(() => {
			if (pauseScreenRef.current && !PlayerInterface.action("isPlaying"))
				pauseScreenRef.current.classList.add("pauseScreen-visible");
		}, 10000);
	}

	const displayControlsUI = (toShow, onBack) => {

		if (!playerUIRef.current) return console.log("no player UI");

		if (toShow) {
			playerUIRef.current.classList.add("showControls");
		} else {
			let [playBtn] = document.getElementsByClassName("playBtn");
			let [epg_popup] = document.getElementsByClassName("epg_popup");
			if (!onBack && playBtn && playBtn.getAttribute("data-playing") == "false" || epg_popup) return;
			playerUIRef.current.classList.remove("showControls");
		}

	}

	const startPlayerUIAnimation = useCallback(() => {

		clearTimeout(playerUIAnimationTimeout);

		displayControlsUI(true); // first display the controls

		pauseScreenRef.current.classList.remove("pauseScreen-visible");

		if (!PlayerInterface.action("isPlaying")) {
			startPauseScreenAnimation();
		}

		// then hide it after X (api) seconds
		playerUIAnimationTimeout = setTimeout(() => displayControlsUI(false), progressBarTimeout);

	}, [progressBarTimeout]);

	const onUITransitionEnd = useCallback(() => {
		const isControlsVisible = playerUIRef.current.classList.contains("showControls");

		if (!isControlsVisible) {
			dispatch(setKeyHandler(KeyHandlerCode.CONTROLS));
		}
	}, [dispatch]);

	const showArticle = useCallback(() => {
		PlayerInterface.controller("pause");
		document.removeEventListener("mousemove", startPlayerUIAnimation);
		playerUIRef.current.removeEventListener("transitionend", onUITransitionEnd);
		setDisplayArticle(true);
	}, [onUITransitionEnd, startPlayerUIAnimation]);

	const closeArticle = useCallback(() => {
		PlayerInterface.controller("play");
		document.addEventListener("mousemove", startPlayerUIAnimation);
		playerUIRef.current.addEventListener("transitionend", onUITransitionEnd);
		setDisplayArticle(false);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const goToPlayBtn = useCallback(() => {
		dispatch(setKeyHandler(KeyHandlerCode.CONTROLS));
	}, [dispatch]);

	const mouseLeftCarousels = useFunction(goToPlayBtn);

	return ReactDOM.createPortal(
		<div className="video_container_inner fullScreen" ref={prevPosRef} role="dialog" aria-label='entered video' tabIndex={-1}>

			<div id="player" ref={playerRef} style={{
				backgroundImage: selectedVideo.content_type === "audio" ? `url(${ selectedVideo.video_cover || selectedVideo.thumbnail })` : null
			}}>
				<StreamLoader ref={loaderRef} />
				{isPlayingAds && <AdsMessage img={selectedVideo.thumbnail} />}
				{displayWaterMark && <Watermark watermark_image={props.watermark_image} />}
			</div>

			{displayArticle && <Article video={selectedVideo} closeArticle={closeArticle} />}

			<div className="videoOverlay">
				{/* {videoHasInit && */}
				<div className="player_UI" ref={playerUIRef} style={{ zIndex: 9999999 }}>
					<VideoControls
						videoHasInit={videoHasInit}
						selectedVideo={selectedVideo}
						displayArticle={showArticle}
						exitVideo={exitVideo}
					>
						<div className="carouselManagerHolder" onMouseLeave={mouseLeftCarousels}>
							<CarouselManager
								content={videoPlayerContent}
								carouselManagerType={CarouselManagerTypes.PLAYER}
								type="player"
								carouselHeader="h3"
								reachedTop={goToPlayBtn}
								enter={setContent}
							/>
						</div>
					</VideoControls>
				</div>
			</div>

			<PauseScreen ref={pauseScreenRef} title={selectedVideo.title} description={selectedVideo.description} />
		</div>
		, document.getElementById("videoContainer"))
}

export default WithKeyHandler(VideoPlayer, "player");

