import React, { useState, useRef, useEffect } from 'react'
import useKeyHandler from 'hooks/useKeyHandler';
import useLanguage from 'hooks/useLanguage';
import { useDispatch } from "react-redux";
import { setKeyHandler } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useFunction from 'hooks/useFunction';

let typingTimeout;

function Input (props) {
	const dispatch = useDispatch();

	const getWord = useLanguage();
	const [inputValue, setInputValue] = useState("");
	const [shouldDisplayTotalVideos, setShouldDisplayTotalVideos] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		return () => {
			clearTimeout(typingTimeout);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (props.isActive) {
			inputRef.current.focus();
		} else {
			inputRef.current.blur();
		}

	}, [props.isActive]);

	useEffect(() => {
		setShouldDisplayTotalVideos(inputValue.length);
	}, [inputValue]);

	const handleChange = (event) => {
		const inputValue = event.type === "change" ? event.currentTarget.value : event;
		clearTimeout(typingTimeout);
		// 	if (inputValue.length > inputRef.current.value) // delete with a remote control key
		// 		if (inputValue.length < 3) return
		setInputValue(inputValue);

		typingTimeout = setTimeout(() => {
			props.handleInputChange(inputValue);
		}, 500);
	}

	const goToGrid = () => {

		let movingDiv = document.getElementsByClassName("movingDiv")[0];

		if (movingDiv && movingDiv.children.length > 0) {
			dispatch(setKeyHandler(KeyHandlerCode.GRID));
		} else {
			dispatch(setKeyHandler(KeyHandlerCode.MENU));
		}

	}

	const back = () => {

		if (!inputRef.current.value) return goToGrid();

		let newValue = inputRef.current.value.slice(0, -1);
		handleChange(newValue);

	}

	const handleClick = useFunction(() => dispatch(setKeyHandler(KeyHandlerCode.SEARCH_INPUT)));

	useKeyHandler({
		keys: {
			back,
			down: goToGrid,
			left: () => {
				if (inputRef.current.selectionStart == 0) goToGrid();
			},
			enter: () => {
				inputRef.current.focus();
			},
			default: (event) => {

				let key = event.keyCode;

				console.log(key);

				if (key == 8) {
					if (!inputRef.current.value || inputRef.current.selectionStart == 0) goToGrid();
					return
				}

				if ((key >= 44 && key <= 92) || (key >= 160 && key <= 176) || (key >= 219 && key <= 222) || key == 229) {
					let value = inputRef.current.value.split("");
					value.splice(inputRef.current.selectionStart, 0, event.key);
					handleChange(value.join(""));
					event.preventDefault();
				}

			}
		},
		isActive: props.isActive
	})

	return (
		<div className="search_input_container" role="search">

			<input
				id="searchInput"
				type="text"
				autoComplete="off"
				className={ props.isActive ? "active" : null }
				ref={ inputRef }
				value={ inputValue }
				onChange={ handleChange }
				onClick={ handleClick }
				style={ { border: `4px solid ${props.color}`, color: props.color } }
				placeholder={ getWord("search_placeholder") }
			/>

			<div className={ "resultsNum" + (shouldDisplayTotalVideos ? " visible" : "") }>
				{ <p>{ getWord("found") } <b>{ props.length }</b> { getWord("videos") }</p> }
			</div>
		</div>
	)
}

export default WithKeyHandler(Input, KeyHandlerCode.SEARCH_INPUT);
