import React, { useCallback, useEffect, useRef } from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { KeyHandlerCode } from 'data/constants';
import { setKeyHandler } from 'Services/redux/app/actions';
import WithKeyHandler from 'HOC/WithKeyHandler';
import usePopup from 'hooks/usePopup';
import './popups.css';

function Popup() {
  const { popupData } = useSelector(({ app }) => ({
    popupData: app.popupData,
  }), shallowEqual);

  const { closePopup } = usePopup();

  const dispatch = useDispatch();
  const popupRef = useRef();

  useEffect(() => {
    if (popupData && popupRef.current) {
      popupRef.current.focus();
      dispatch(setKeyHandler(KeyHandlerCode.EXIT_POP_UP));
    }
  }, [dispatch, popupData])

  const exitPopup = useCallback((keyHandler) => {
    closePopup();
    dispatch(setKeyHandler(keyHandler));

    if (popupData) {
      popupData.props.onClose && popupData.props.onClose();
    }
  }, [dispatch, popupData, closePopup]);

  if (popupData === null)
    return null;

  return (
    <div className="fullScreen centerlize popup_container" role="dialog" aria-labelledby="dialogTitle" aria-describedby="dialogDesc" ref={popupRef} tabIndex={-1}>
      <popupData.Component exitPopup={exitPopup} popupProperties={popupData.props} />
    </div>
  )
}

export default WithKeyHandler(Popup, KeyHandlerCode.EXIT_POP_UP)