import React from 'react'
import { useSelector, shallowEqual } from "react-redux";
// import LiveStreamPopUp from '../popups/VideoErrorPopup';
import Popup from './Popup';
import FadeScreen from '../Widgets/fadeScreen/FadeScreen';

const GlobalPopups = () => {

	const { displayFadeScreen } = useSelector(({app}) => ({
		displayFadeScreen: app.displayFadeScreen,
	}), shallowEqual);

	return (
		<>
			<Popup />

			{<FadeScreen displayFadeScreen={displayFadeScreen} />}
		</>
	)
}

export default React.memo(GlobalPopups);
