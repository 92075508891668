export class MediaAttributes {
  constructor() {
    this.id = "castifyPlayer";
    this.className = "castifyPlayer";
    this.controlsList = "nodownload";
    this.playsInline = true;

    if (window.settings.platformSettings.playerCrossorigin)
      this.crossOrigin = "anonymous";
  }
}

export const utility = {
  getTarget(target) {
    if (!target) throw Error(`No target found`);

    if (typeof target === "string") {
      const elem = document.querySelector(target);
      if (elem)
        return elem
      else
        throw Error(`no target found: ${target}`)
    } else {
      return target
    }
  },

  // this function determines if we should use the hls.js extenstion
  // the prefer way is to let the platform handle HLS videos
  // but since the world is not perfect there are some platforms that can't do that, like: vizio and emulator(chrome browser)
  getPlayerType(video, platformNeedsHlsExtension) {
    // if the platform can't play HLS streams check first if the video is hls or not
    if (platformNeedsHlsExtension) {
      return video.use_hls ? "hls_extension" : "normal";
    }

    // if the platform able to play hls send normal and the platform will handle the rest :)
    return "normal";
  },

  getMimeType(video) {
    // if we have mime type return it
    if (video.mime_type) return video.mime_type;

    // if not check if the video is hls
    return video.use_hls ? "application/vnd.apple.mpegurl" : "video/mp4";
  }
}