import Loader from '../loaders/Loader';
import React from 'react';
import './fadeScreen.css'

function FadeScreen({ displayFadeScreen }) {
	return (
		<div id="fadeScreen" className={"centerlize " + (displayFadeScreen ? "active" : "hidden")}>
			{displayFadeScreen && <Loader />}
		</div>
	)
}

export default React.memo(FadeScreen);
