export const keyCodes = {
	UP: 38,
	DOWN: 40,
	LEFT: 37,
	RIGHT: 39,
	ENTER: 13,
	BACK: null,
	EXIT: null,
	STOP: 413,
	PLAY: 415,
	PAUSE: 19,
	FORWARD: 417,
	REWIND: 412,
	RED: 403,
	GREEN: 404,
	YELLOW: 405,
	BLUE: null,
	TOGGLE_PLAY: null
}

/**
 * Set the platform key code.
 * 
 * @param {object} platformKeyCodes Platform's key code 
 */
export const setKeyBinding = platformKeyCodes => {
	Object.assign(keyCodes, platformKeyCodes);
	Object.freeze(keyCodes); // prevent form modify this object accidentally
}