

import { useCallback } from "react";
import { useSelector, shallowEqual } from "react-redux";

export default function useLanguage() {
  const { translation } = useSelector(({app}) => ({
    translation: app.translation
  }), shallowEqual);

  const getWord = useCallback(word => translation[word] || "", [translation]);

  return getWord;
}
