import { shortAmount, formatTimeString } from "utils/utiliesFunctions"

const memoMetaData = {}

export const buildMetaData = (video) => {
	// create unique id for each video, it also take in mind when video can be shown in different carousels
	const memoItemId = video.id;

	const titleData = {
		value: video.carouselTitle,
		display: true,
		className: "category",
		icon: "https://cdn.castify.ai/files/app/category.svg"
	}

	// get it form cache, just chagne the carousel title value (in case the videos is exist in a different carousel)
	if (memoMetaData[memoItemId]) {
		const newData = [...memoMetaData[memoItemId]];
		newData[0] = titleData;
		return newData;
	}

	const metaDataValues = [
		titleData,
		{
			value: +video.likes ? shortAmount(video.likes) : 0,
			display: +video.likes,
			className: "likes",
			icon: "https://cdn.castify.ai/files/app/like.svg"
		},
		{
			value: +video.views ? shortAmount(video.views) : 0,
			display: +video.views,
			className: "views",
			icon: "https://cdn.castify.ai/files/app/eye.svg",
		},
		{
			value: video.is_live_streaming ? "Live" : formatTimeString(video.videoDuration),
			display: video.videoDuration,
			className: "duration",
			icon: "https://cdn.castify.ai/files/app/clock.svg"
		},
		{
			value: video.resolution,
			display: !!video.resolution,
			className: "resolution",
			icon: ""
		}
	]

	// concat the static object with the new created one
	const newData = [...metaDataValues, ...dynamicMetaData(video)].filter(item => item.display)

	// memo this value for faster access next time
	memoMetaData[memoItemId] = newData;

	return newData;
}

const dynamicMetaData = video => {
	const bonusMetadataList = [];

	if (video.parental_control) {
		const description = video.parental_control[0].title;

		bonusMetadataList.push({
			className: "parent_control",
			icon: "https://cdn.castify.ai/files/app/parentControl.svg",
			value: description,
			display: !!description
		})
	}

	return bonusMetadataList;
}