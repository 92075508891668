const initialState = {
  /**
   * @type {object} Current playing video
   */
  selectedVideo: {},

  /**
   * @type {object} Next playing video
   */
  nextVideo: {},

  /**
   * @type {boolean} Display video player
   */
  displayVideoPlayer: false,

  /**
   * @type {Object[]} List of carouesls the player can play
   */
  videoPlayerContent: [],

  /**
   * @type {string} where was the last focused key before entering the video player
   */
  keyHandlerBeforePlayer: "",
};

/**
 * Video reducer to interact with player states like: the playing video or the next video
 * 
 * @param {object} state InitialState
 * @param {object} action Action object to update the state  
 */
export default function videoReducer(state = initialState, action) {

  switch (action.type) {
    case "video/init":
      return {
        ...state,
        ...action.payload,
        displayVideoPlayer: true
      }
    case "video/set-selected-video":
      return {
        ...state,
        selectedVideo: action.payload.video,
        nextVideo: action.payload.nextVideo
      }
    case "video/set-video-visibility": return { ...state, displayVideoPlayer: action.payload }
    default: return state;
  }
}