import React, { useEffect, useRef } from 'react';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import PageHeader from '../components/pagesWidgets/PageHeader';
import SideImage from '../components/pagesWidgets/SideImage';
import { setFadeScreen, setKeyHandler } from '../Services/redux/app/actions';

const WithPage = (Component, KeyHandlerCode) => {

	const WithStateComponent = (props) => {

		const dispatch = useDispatch();
		const { graphic, activePage } = useSelector(({app}) => ({
			graphic: app.graphic,
			activePage: app.activePage,
		}), shallowEqual);

		const pageContainerRef = useRef();

		useEffect(() => {
			dispatch(setFadeScreen(false));

			if (KeyHandlerCode)
				dispatch(setKeyHandler(KeyHandlerCode));

		}, [dispatch, props.type]);

		useEffect(() => {
			Object.assign(pageContainerRef.current.style, {
				backgroundImage: `url('${activePage.page_background_image || graphic.backgroundImg}')`,
				backgroundColor: graphic.backgroundColor,
				color: activePage.graphic.text_color
			});

		}, [activePage.graphic.text_color, activePage.page_background_image, graphic.backgroundColor, graphic.backgroundImg]);

		const buildPageContainerClasses = () => {
			return `page-container list ${activePage["page_client_class"]}`
		}

		return (
			<div className={buildPageContainerClasses()} ref={pageContainerRef}>
				<div className="page_content">
					{!props.noTitle && <PageHeader appName={graphic.appName} title={activePage.page_title || activePage.menu_title} color={activePage.graphic.title_color} />}
					<div className="page-inner">
						<Component page={activePage} {...props} />
					</div>
				</div>

				{activePage.page_image && <SideImage image={activePage.page_image} />}
			</div>
		)
	}

	return WithStateComponent;
}

export default WithPage
