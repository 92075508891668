export default function getGlobalGraphicObj(graphicObj, additionalInfo) {
	const desiredProps = [
		"appLogo",
		"appName",
		"defaultThumbnail",
		"outline",
		"progressBarTimeout",
		"backgroundImg",
		"backgroundColor",
		"watermark_player_permanent",
		"loader_image",
		"show_carousels",
		"splashScreen",
		"rtl",
		"is_player_app"
	];

	return desiredProps.reduce((total, current) => {
		return { ...total, [current]: graphicObj[current] }
	}, additionalInfo)
}