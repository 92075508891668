import React, { useEffect, useRef } from 'react'

let isImg0Active = true

function ImageTransition(props) {
	const img0 = useRef();
	const img1 = useRef();

	useEffect(() => {

		const videoToShow = props.selectedVideo.video_cover || props.selectedVideo.thumbnail;
		if (isImg0Active) {
			img0.current.src = videoToShow;
			img1.current.alt = props.selectedVideo.title;
		} else {
			img1.current.src = videoToShow;
			img1.current.alt = props.selectedVideo.title;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.selectedVideo]);

	const onLoad = () => {
		if (isImg0Active) {
			img1.current.style.opacity = "0"
		} else {
			img1.current.style.opacity = "1"
		}
		isImg0Active = !isImg0Active
	}

	const imageDefault = ({ currentTarget }) => {
		currentTarget.src = props.defaultThumbnail
	}

	return (
		<div className="detailed_image_container">
			<div>
				<img decoding="async" className="imgBG" onLoad={onLoad} onError={imageDefault} ref={img0} alt="" />
				<img decoding="async" className="imgBG" onLoad={onLoad} onError={imageDefault} ref={img1} alt="" />
			</div>
		</div>
	)
}

export default React.memo(ImageTransition);