import React from 'react';
import './loaders.css'

export default function StoreLoader() {
	return (
		<div className="store-loader-container">
			<div className="store-loader" />
		</div>
	)
}
