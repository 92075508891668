import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setFadeScreen, setKeyHandler, setPopupType } from "Services/redux/app/actions";
import { KeyHandlerCode } from 'data/constants';
import { CarouselManagerTypes } from 'data/constants';
import PropTypes from 'prop-types';

// components
import CategoriesTopCarousel from './carousels/MultiCategoriesCarousel';
import CarouselManager from 'components/carousel/CarouselManager';

// style
import './multiCategories.css';
import ExitPopUp from 'components/popups/exitPopup/ExitPopUp';

// import Carousel from 'components/carousel/Carousel';
function MultiCategories ({ container, type, settings }) {

	const [activeCategoryIndex, setActiveCategoryIndex] = useState(0);

	const dispatch = useDispatch();
	const { backgroundImage, backgroundColor, categoryContent, hasMenu } = useSelector(({ app, entities }) => ({
		backgroundImage: container.graphic.page.page_background_image || container.graphic.container.background_image || app.graphic.backgroundImg,
		backgroundColor: container.graphic.page.page_background_color || container.graphic.container.background_color || app.graphic.backgroundColor,
		categoryContent: entities.categories[container.content[activeCategoryIndex]].content,
		hasMenu: app.graphic.hasMenu

		// categoryContent: entities.categories[container.content[0].content[activeCategoryIndex].content
	}), shallowEqual);

	const multiPageRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			dispatch(setFadeScreen(false));
			dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
		}, 500);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		Object.assign(multiPageRef.current.style, {
			backgroundImage: `url('${ backgroundImage }')`,
			backgroundColor
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [backgroundImage, backgroundColor])

	useEffect(() => {
		setTimeout(() => {
			dispatch(setFadeScreen(false));
		}, 500);

		//  eslint-disable-next-line react-hooks/exhaustive-deps
	}, [container.entity_id, dispatch]);

	const goToCategories = useCallback(() => {
		dispatch(setKeyHandler(KeyHandlerCode.MULTI_CATEGORIES));
	}, [dispatch]);

	const goToMenu = useCallback(() => {

		// old code
		// const newKeyhandlerCode = settings.horizntalEdge || hasMenu ? KeyHandlerCode.MENU : null;
		// if (newKeyhandlerCode)
		// 	dispatch(setKeyHandler(newKeyhandlerCode));

		// new code
		let existMenu = document.getElementsByClassName("menu-float-ui").length > 0;
		if (existMenu) dispatch(setKeyHandler(KeyHandlerCode.MENU));
		else dispatch(setPopupType(ExitPopUp));

	}, [dispatch, settings.horizntalEdge]);

	return (
		<div id="categories" ref={multiPageRef}>

			<CategoriesTopCarousel
				categories={container.content}
				setActiveCategory={setActiveCategoryIndex}
				settings={settings}
				containerStyle={container.graphic.container}
			/>

			<div className="carouselManager_container">
				<CarouselManager
					type={type}
					carouselManagerType={CarouselManagerTypes.MULTI_CATEGORIES}
					carouselHeader="h3"
					content={categoryContent}
					reachedTop={goToCategories}
					reachedLeft={goToMenu}
					settings={settings}
				/>
			</div>
		</div>
	)
}

MultiCategories.propTypes = {
	container: PropTypes.object.isRequired, // the container, holds data like carouseles, top carousel type, page_id and more...
	type: PropTypes.string.isRequired, // what type of carouselManager will this page render home/page
	settings: PropTypes.object // how carousel manger will behave
}

export default React.memo(MultiCategories);