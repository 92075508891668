import React from 'react';
import { useSelector, shallowEqual } from "react-redux";
import { KeyHandlerCode } from 'data/constants';

// in order to check if carousel is active we need to check:
// 1- it has to correct key handler
// 2- in case of multiple carousel page we need to check the index of the carousel manger with the active category
// 3- check if the carouselManager type is the same as activeCarouselManger (redux)
const WithKeyHandler = (Component, componentKeyHandler) => {

	const KeyHandlerComponent = (props) => {

		const { keyHandler: activeKeyHandler, activeCarouselManager } = useSelector(({ app }) => ({
			keyHandler: app.keyHandler,
			activeCarouselManager: app.activeCarouselManager
		}), shallowEqual);

		const isActive = () => {
			if (componentKeyHandler === "carouselManager")
				return activeKeyHandler === KeyHandlerCode.CAROUSEL && activeCarouselManager === props.type
			else if (componentKeyHandler === "player")
				return activeKeyHandler !== "ads"
			else return componentKeyHandler === activeKeyHandler
		}

		return <Component isActive={ isActive() } isPopupOpen={ activeKeyHandler === KeyHandlerCode.EXIT_POP_UP } { ...props } />

	}

	return React.memo(KeyHandlerComponent);

}

export default WithKeyHandler;