import React from 'react';
import './error.css';
import BrowserErr from './typesOfErrors/Browser_err';
import NetworkErr from './typesOfErrors/Network_err';
import ServerErr from './typesOfErrors/Server_err';

function Error(props) {

	const getErrorHtml = (err_type) => {
		switch (err_type) {
			case "device": return <BrowserErr />;
			case "network": return <NetworkErr />;
			case "maintenance":
			default: return <ServerErr />;
		}
	}

	return (
		<div className="errorContainer fullScreen centerlize">
			<div className="err_msg">
				{getErrorHtml(props.err_type)}
			</div>
		</div>
	)
}

export default Error;