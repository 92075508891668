import React, { useState, useEffect, useCallback } from 'react';
import { setKeyHandler } from "Services/redux/app/actions";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import WithKeyHandler from 'HOC/WithKeyHandler';
import useLanguage from 'hooks/useLanguage';
import useKeyHandler from 'hooks/useKeyHandler';

// Components
import ProgressBar from './components/progressBar/ProgressBar';
import ActionRow from './components/optionsPopup/actions/ActionRow';
import PlayRow from './components/playRow/PlayRow';

//services
import { KeyHandlerCode } from 'data/constants';

// style
import './videoControlsStyle.css';

import { buildPlayerOptions } from '../utilies/playerOptions';
// import PlayerOptions from '../component/PlayerOptions/PlayerOptions';

function VideoControls (props) {
	const dispatch = useDispatch();

	const { showCarousel } = useSelector(({ app, video }) => ({
		showCarousel: app.graphic.show_carousels && video.videoPlayerContent.length > 0
	}), shallowEqual);

	const [rowPosition, setRowPosition] = useState(1);
	const [playerOption, setPlayerOption] = useState([]);
	const getWord = useLanguage();

	/**
	 * Run everytime the player content changed
	 */
	useEffect(() => {
		setPlayerOption(buildPlayerOptions(props.selectedVideo, getWord))
	}, [props.selectedVideo, getWord]);

	const downFromActions = useCallback(() => {
		setRowPosition(props.selectedVideo.is_live_streaming ? 1 : 0);
	}, [props.selectedVideo]);

	useKeyHandler({
		keys: {
			down: () => {
				switch (rowPosition) {
					case -1:
						setRowPosition(props.selectedVideo.is_live_streaming ? 1 : 0);
						break;
					case 0:
						setRowPosition(1);
						break;
					case 1:
						showCarousel && dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
						break;
					default: break;
				}
			},
			up: () => {
				switch (rowPosition) {
					case 0:
						setRowPosition(-1);
						break;
					case 1:
						// if the video is live check if we have actions if not stay where you are
						const actionsNewPos = playerOption ? -1 : 1;
						setRowPosition(props.selectedVideo.is_live_streaming ? actionsNewPos : 0);
						break;
					default: break;
				}
			}
		},
		isActive: props.isActive && rowPosition !== -1,
		dependency: [rowPosition, props.selectedVideo.is_live_streaming],
	})

	return (
		<>
			{/* <PlayerOptions options={playerOption} /> */ }
			{ playerOption && <ActionRow
				playerOption={ playerOption }
				isActive={ rowPosition === -1 && props.isActive }
				rowIndex={ -1 }
				downParent={ downFromActions }
				displayArticle={ props.displayArticle }
				setRowPosition={ setRowPosition }
				exitVideo={ props.exitVideo }
			/> }

			<div className={ `bottomSection ${showCarousel ? "" : "noCarusels"}` }>
				<div className={ `inner ${!props.isActive ? "carouselActive" : ""}` }>
					<h2 className="stream_title">{ props.selectedVideo.title }</h2>

					{ props.videoHasInit && <ProgressBar isActive={ rowPosition === 0 && props.isActive } isLiveStreaming={ props.selectedVideo.is_live_streaming } setRowPosition={ setRowPosition } /> }
					{ props.videoHasInit && <PlayRow isActive={ rowPosition === 1 && props.isActive } setRowPosition={ setRowPosition } rowIndex={ 1 } /> }

				</div>

				{ showCarousel && props.children }
			</div>
		</>
	)
}

export default WithKeyHandler(React.memo(VideoControls), KeyHandlerCode.CONTROLS);