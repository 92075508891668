/*eslint no-throw-literal: "off"*/
import { getLocalStorage } from "utils/storage/localStorage";
import { isRestrictedItem } from "./versionUtilies";

let helper = {};

/**
 * Build The data we need from v7 json.
 * 
 * @param {object} json The app json 
 * @param {string} vast parsed vast 
 * 
 * @returns {object} New json strcture. 
 */
export const v7Init = (json, vast) => {
	try {
		// id app is player app do not build the menu
		return {
			menu: json.graphic.is_player_app ? null : buildMenu(json.menu),
			entites: buildEntites(json),
			graphic: json.graphic,
			ads: buildAds(json.Ads, vast),
			appInfo: {
				parentHash: json.Info.family_hash
			}
		}
	}
	catch (error) {
		throw {
			version: "7",
			...error
		}
	}
}

/**
 * Build menu object.
 *  
 * @param {object} menu Menu objhect from main json. 
 * 
 * @returns {object} New menu object 
 */
function buildMenu (menu) {
	try {
		const { pages, order_pages = [], graphics, menu_open } = menu;

		return {
			menuOpen: menu_open,
			pages: menu.pages,
			graphic: {
				backgroundColor: graphics.menu_background_color,
				text_color: graphics.menu_text_color,
				hover_color: graphics.menu_text_color_hover,
				active_color: graphics.menu_text_color_selected,
				images: {
					logo_close: graphics.top_image_close_menu || menu.menu_logo_image,
					logo_open: graphics.top_image_open_menu,
					bottom_logo_close: graphics.bottom_image_close_menu,
					bottom_logo_open: graphics.bottom_image_open_menu
				}
			},
			links: order_pages.map(id => {
				const page = pages[id];
				return {
					pageId: page.page_id,
					title: page.menu_title,
					icon: page.page_menu_icon,
					page_client_class: page.page_client_class
				}
			})
		}
	}
	catch (error) {
		throw {
			stage: "Menu",
			error
		}
	}
}

/**
 * Build all entities like - pages, video carouesl.
 * We make sure there are only one
 * 
 * @param {object} json 
 * 
 * @returns {object} All entities
 */
function buildEntites (json) {
	try {

		const { menu: { order_pages, pages = {} } } = json;
		const allPages = {};
		const homePageId = order_pages[0];
		const videos = buildVideos(json.content);
		const carousels = buildCarousels(json.playlists, videos);
		const categories = buildCategories(json.categories);

		helper = {
			categories: json.categories,
			carousels
		}

		if (!json.playlists || !json.content) {
			throw new Error("No contents or playlists");
		}

		for (const page in pages) {

			const currentPage = pages[page];
			const pageId = currentPage.page_id === homePageId ? "homePage" : currentPage.page_id;

			switch (currentPage.widget_type) {
				case "playlists":
					// filter the restricted or the undefined carousels
					const playlists = currentPage.playlists.filter(playlistID => !!carousels[playlistID]);
					allPages[pageId] = factoryBuilder("page", currentPage, playlists, "playlists");
					break;
				case "categories":
					const containers = [];
					currentPage.categories.forEach(categoryID => {
						const _builtCategory = factoryBuilder("container", json.categories[categoryID]); // build it
						containers.push(_builtCategory);
					});
					const type = containers.length > 1 ? "multiPage" : "categories";
					const contents = containers.length > 1 ? containers : containers[0];

					allPages[pageId] = factoryBuilder("page", currentPage, contents, type);
					break;
				default: break;
			}
		}

		helper = undefined;

		return {
			videos,
			carousels,
			categories,
			pages: allPages
		}
	}
	catch (error) {
		throw {
			stage: "Building entities",
			error
		}
	}
}

/**
* Build object with all the videos in the app, add additional fields like last watched position
* in 'my list' and remove all restricted videos like geo videos. 
*
* @param {object} _content content object from main json containing all content.
*
* @returns All videos we can watch and interact, without the restrcited ones.
*/
const buildVideos = (_content) => {
	try {

		const videosList = {};
		const continueWatching = getLocalStorage("continueWatching", {});
		const myList = getLocalStorage("myList", {});

		// Iterate through 'contiune watching' list
		for (const videoId in continueWatching) {

			// since we save data in local storage we may have videos from other apps (only on emulators) in tv there shouldn't be any problem
			if (!_content[videoId]) continue;

			// create new object and add the current time
			videosList[videoId] = {
				currentTime: +continueWatching[videoId]
			}
		}

		// Iterate through the 'my list' 
		for (const videoId in myList) {

			// since we save data in local storage we may have videos from other apps (only on emulators) in tv there shouldn't be any problem
			if (!_content[videoId]) continue;

			// add new field called inList
			videosList[videoId] = {
				...(videosList[videoId] || {}),
				inList: true
			}
		}

		for (const videoID in _content) {
			// take the entire content object and combine it with the other fileds (like inList, currentTime) 
			videosList[videoID] = {
				..._content[videoID],
				...(videosList[videoID] || {})
			}

			// now remove every restricted video
			if (isRestrictedItem(_content[videoID])) {
				delete videosList[videoID];
			}
		}

		return videosList;
	} catch (error) {
		throw {
			stage: "Building videos",
			error
		}
	}

}

/* build all the carousels 
 1- normal carousel
 2- empty carousel- we can intercat with it but we might not display it at all
 3- restricted - carousel which should not even exists in the app, not interaction and no displayed
*/
function buildCarousels (playlists, contents) {

	// contents is the list with all the videos without the restricted ones
	try {

		const data = {};
		const clientPlaylists = {};

		const getData = (data) => {

			const list = [];

			for (const videoId in data) {
				const video = contents[videoId];
				// since we save data in local storage we may have videos from other apps (only on emulators) in tv there should be a problem
				if (!video) continue;
				list.push(videoId);
			}

			return list;

		};

		for (const playlistID in playlists) {

			const _playlist = playlists[playlistID];
			let videos = [];
			let emptyText = "";

			switch (_playlist.feature_client) {
				case "continue_watching":
					clientPlaylists.continueWatching = playlistID;
					videos = getLocalStorage("continueWatching", [], getData);
					emptyText = "Things you watched will be here";
					break;
				case "my_list":
					clientPlaylists.myList = playlistID;
					videos = getLocalStorage("myList", [], getData);
					emptyText = "Everything you added to your list will be here";
					break;
				default: videos = _playlist.itemIds; break;
			}

			// remove videos that are restricted ( if they are not exists in the content object they are restricted)
			videos = videos.filter(videoId => !!contents[videoId]);

			const playlist = factoryBuilder("playlist", _playlist, videos, emptyText);

			// check if the playlist is restriced (rule #3) do not add it to the global playlist array
			// that way this carousel will not even be exsited
			if (!playlist.isRestricted) data[playlistID] = playlist;
		}

		window.clientPlaylists = clientPlaylists;
		return data;
	} catch (error) {
		throw {
			stage: "Building carousels",
			error
		}
	}
}

/**
 * Build all cateogories from app. using the "CategoryCretor" class.
 * 
 * @param {object} categories The cateogires object from main json.
 * 
 * @returns list of Caregories class
 */
const buildCategories = (categories) => {

	const categoriesList = {};

	for (const categoryID in categories) {
		const category = categories[categoryID];

		// if it has categories child it's a container
		if (category.children_category_ids.length > 0) continue;

		const builtCategory = factoryBuilder("category", category, true);

		// check if the playlist is restriced (rule #3) do not add it to the global categories array
		// that way this categoryl will not even be exsited
		if (!builtCategory.isRestricted)
			categoriesList[categoryID] = builtCategory;
	}

	return categoriesList;
}

/**
 * Get the ads object from app json, but convert the 'adPod' to number (string from server),
 * also convert the 'adInterval' to seconds.
 * 
 * @param {object} ads Ads object from json.
 * @param {string} vastURL parsed vast url.
 * 
 * @returns New ads object.
 */
const buildAds = (ads, vastURL) => {
	return {
		...ads,
		adInterval: ads.adInterval * 1000,
		adPod: +ads.adPod,
		vastURL
	}
}

// const subChildBuilder = (self) => {
// 	const isPlaylist = "playlist_ids" in self;

// 	if (isPlaylist) {
// 		// filter the restricted or the undefined carousels we might have
// 		return self.playlist_ids.filter(playlistID => !!helper.carousels[playlistID]);
// 	} else {
// 		const mediaItems = [];
// 		for (const id of self.children_category_ids) {
// 			const content = factoryBuilder("category", helper.categories[id]); // build it

// 			mediaItems.push(content);
// 		}
// 		return mediaItems;
// 	}
// }

/**
 * Fucntion to buildEntities with. <br>
 * Should be used over then using the entity class directly
 * 
 * @param {string} type What kind of entity to build. 
 * @param  {...any} data The data the entity needs. 
 * @returns 
 */
const factoryBuilder = (type, ...data) => {
	if (isRestrictedItem(data[0])) return { isRestricted: true };
	switch (type) {
		case "playlist": return new PlaylistCreator(...data);
		case "category": return new CategoryCreator(...data);
		case "container": return new ContainerCreator(...data);
		case "page": return new PageCreator(...data);
		default: console.warn(`Unknown media type: ${type}`);
	}
}

/**
 * Build playlist object.
 * Playlist contains list of videos.
 * 
 * @class
 */
class PlaylistCreator {
	constructor (playlist, videos, emptyText) {

		this.entity_id = playlist.entity_id;
		this.title = playlist.name;
		this.type = playlist.keyword;
		this.active_color = playlist.color;
		this.carousel_title_color = playlist.title_color;
		this.text_color = playlist.text_color;
		this.videos = videos.slice(0, 30);
		this.display = showCarousel(playlist.feature_client, videos);

		if (playlist.feature_client) {
			this.kind = playlist.feature_client;
		}

		if (emptyText) {
			this.emptyText = emptyText;
		}
	}
}

/**
 * Build category object.
 * Category contains list of carouesls.
 * 
 * @class
 */
class CategoryCreator {
	constructor (category, temp) {
		this.entity_id = category.entity_id;
		this.title = category.name;
		this.image = category.image;
		this.graphic = category.graphic; // added for the new design
		this.content = [...(category.playlist_ids || category.children_category_ids)]
	}
}

/**
 * Build container object, container is the first level category in a page.
 * 
 * @class
 */
class ContainerCreator {
	constructor (container) {
		this.entity_id = container.entity_id;
		this.title = container.name;
		this.image = container.image;
		this.content = [...(container.playlist_ids || container.children_category_ids)];

		this.type = container.keyword;
		this.graphic = container.graphic;
		this.contentType = container.playlist_ids ? "playlists" : "categories";
	}
}

/**
 * Build page object.
 * 
 * @class
 */
class PageCreator {
	constructor (page, pageContainer, mediaField) {
		this.id = page.page_id;
		this.type = page.page_client_class;
		this.graphic = {
			container: pageContainer.graphic || {},
			page: page.graphic || {}
		};

		// each page type has it's own properties, or "relevant" properties to display relevent data
		switch (mediaField) {
			case "playlists":
				this.content = pageContainer.content || pageContainer;
				this.firstVideo = findFirstVideo(this.content, 0);
				break;
			case "categories":
				this.content = pageContainer.content
				// this.content = content[mediaField] || content;
				this.graphic.container.type = pageContainer.type;
				break;
			case "multiPage":
				const _contents = pageContainer[mediaField] || pageContainer;

				this.content = _contents.map(container => {
					const page = {
						page_id: container.entity_id,
						page_client_class: container.contentType === "playlists" ? "detailed" : "multiCategories"
					};
					return factoryBuilder("page", page, container, container.contentType);
				});

				this.items = pageContainer.map(({ entity_id, title, image }) => ({ entity_id, title, image }));
				break;
			default: break;
		}
	}
}

/**
* Check if the carousel should be visible
*
* @returns true | false
*/
const showCarousel = (feature_client, videos) => {
	// if the carousel is "my list" type always display it
	if (feature_client === "my_list") return true;

	// else display the carousel only if it contains videos
	return videos.length > 0;
}

/**
 * Find the virst video in detailed page.
 * 
 * @param {object} content The whole carouesls.
 * @param {number} index The index of the current item in carouesl we are checking.
 * 
 * @returns 
 */
const findFirstVideo = (content, index) => {

	const carouselId = content[index];

	if (carouselId) {
		const carousel = helper.carousels[carouselId];
		if (carousel) {
			if (carousel.videos.length) {
				return {
					videoId: carousel.videos[0],
					carouselTitle: carousel.title
				}
			} else {
				return findFirstVideo(content, index + 1)
			}
		} else {
			throw new Error(`No carousel ${carouselId}`)
		}
	} else {

		let error = new Error("No videos found on all carousels or no carousels at all");

		window.dispatchEvent(new ErrorEvent("error", { error }));

		// throw error

	}
}