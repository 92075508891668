import React from 'react'
import ParentControl from './ParentControl'
import VideoDuration from './VideoDuration'

function VideoUI({ is_live_streaming, videoDuration, parental_control, currentTime }) {
  return (
    <div className="video_UI_container">
      <VideoDuration is_live_streaming={is_live_streaming} videoDuration={videoDuration} />
      {parental_control &&
        <div className="parent_control_container">
          {parental_control.map((parentControlItem, index) => <ParentControl key={index} parental_control={parentControlItem} />)}
        </div>
      }
      {!!currentTime && <div className="lastWatch" style={{ width: `${currentTime * 100 / videoDuration}%` }}></div>}
    </div>
  )
}

export default React.memo(VideoUI)

