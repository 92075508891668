import React from 'react';
import './menuItem.css';

function MenuItem (props) {

	const setStyle = () => {

		if (props.isMenuActive && window.settings.platformSettings.animation) {

			const delay = 0.15 + 0.1 * props.index / 2.7;

			return {
				WebkitTransition: `transform .2s ease ${delay}s `,
				transition: `transform .2s ease ${delay}s `,
				// textShadow: props.isItemActive ? `1px 0px 0px ${props.selectedColor}` : "1px 0px 0px rgba(0,0,0,0)"
			}

		} else {

			return {
				WebkitTransition: "none",
				transition: "none",
			}

		}

	}

	return (
		<>
			{ props.menuItem.icon && <img src={ props.menuItem.icon } alt={ props.menuItem.title } /> }
			<span className="menu_item_text" style={ setStyle() }>
				{ props.menuItem.title }
			</span>
		</>
	);

}

export default React.memo(MenuItem);
