// react
import React, { useState, useEffect, useRef, useCallback } from 'react';
import withControlRow from 'HOC/withControlRow';
import useKeyHandler from 'hooks/useKeyHandler';
import useFunction from 'hooks/useFunction';
// components
import NextVideo from '../nextVideo/NextVideo';
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EpgPopup from '../../../../popups/epgPopup/EpgPopup';

// services
import playersCenter from 'Services/Player/playersCenter';
import PauseIcon from "@material-ui/icons/Pause";
import { dataAttr } from 'utils/utiliesFunctions';
import "./playRow.css";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { setVideo } from 'Services/redux/video/actions';

import { parseString } from 'xml2js';
import usePopup from 'hooks/usePopup';

function PlayRow (props) {

	const dispatch = useDispatch();

	const { displayPopup } = usePopup();

	const { nextVideo, selectedVideo, selectedVideoObj } = useSelector(({ video, entities }) => {
		return {
			nextVideo: video.nextVideo,
			selectedVideo: video.selectedVideo,
			selectedVideoObj: entities.videos[video.selectedVideo],
		}
	}, shallowEqual);

	const [isPlaying, setIsPlaying] = useState(true);
	const [epgData, setEpgData] = useState(null);
	const playBtnRef = useRef();

	const currentProgTitleRef = useRef();
	const currentProgTimeRef = useRef();

	const nextProgTitleRef = useRef();
	const nextProgTimeRef = useRef();

	const epgParentRef = useRef();

	// '20221213130838 -0500' to date
	const formatEpgDate = (unix) => {
		let year = unix.substring(0, 4);
		let month = unix.substring(4, 6);
		let day = unix.substring(6, 8);
		let hour = unix.substring(8, 10);
		let minute = unix.substring(10, 12);
		let second = unix.substring(12, 14);
		// let timezone = unix.substring(14, 19);
		return new Date(year, month - 1, day, hour, minute, second, 0);
	}

	const formatEpgTime = (date) => {

		let hours = date.getHours();
		let minutes = date.getMinutes();

		if (hours < 10) hours = `0${hours}`;
		if (minutes < 10) minutes = `0${minutes}`;

		return `${hours}:${minutes}`;

	}

	const setEpg = (epg) => {

		let currentProgram = null;
		let nextProgram = null;

		if (epg && epg.tv && epg.tv.program) {

			let programs = epg.tv.program;

			let currentTime = new Date().getTime();

			for (let i = 0; i < programs.length; i++) {

				if (currentProgram && nextProgram) break;

				let program = programs[i];

				let { start, stop } = program.$;

				if (start && stop) {

					start = formatEpgDate(start);
					stop = formatEpgDate(stop);

					let { title, desc } = program;

					if (desc && desc[0] && desc[0]["_"]) {
						desc = desc[0]["_"];
					} else {
						desc = '';
					}

					if (title && title[0] && title[0]["_"]) {
						title = title[0]["_"];
					} else {
						title = '';
					}

					let programObj = { title, desc, start, stop };

					if (currentTime >= start.getTime() && currentTime <= stop.getTime() && !currentProgram) {
						currentProgram = programObj
					} else if (currentProgram) {
						nextProgram = programObj;
					}

				}

			}

		}

		if (currentProgram && nextProgram) {
			setEpgData({ currentProgram, nextProgram });
		}

	}

	const getEpg = () => {

		setEpgData(null);

		if (selectedVideoObj && selectedVideoObj.is_live_streaming && selectedVideoObj.epg && selectedVideoObj.epg.link) {

			let url = selectedVideoObj.epg.link;

			let req = new XMLHttpRequest();

			req.onreadystatechange = function () {

				if (req.readyState == 4) {

					if (req.status == 200) {

						parseString(req.responseText, function (err, result) {
							if (err) return setEpg(null);
							setEpg(result);
						});

					} else {
						setEpg(null);
					}

				}

			}

			req.onerror = function (error) {
				console.log('error', error);
			}

			req.open('GET', url, true);

			req.send();

		} else {
			setEpg(null);
		}

	}

	const showEpg = () => {
		setEpgData((epgData) => {
			if (epgData && epgData.currentProgram) displayPopup(EpgPopup, epgData.currentProgram);
			return epgData;
		});
	}

	useEffect(() => {

		if (!epgData) return

		let { currentProgram, nextProgram } = epgData;

		if (currentProgram) {
			currentProgTitleRef.current.innerHTML = currentProgram.title;
			currentProgTimeRef.current.innerHTML = formatEpgTime(currentProgram.start) + " - " + formatEpgTime(currentProgram.stop);
		} else {
			currentProgTitleRef.current.innerHTML = "";
			currentProgTimeRef.current.innerHTML = "";
		}

		if (nextProgram) {
			nextProgTitleRef.current.innerHTML = nextProgram.title;
			nextProgTimeRef.current.innerHTML = formatEpgTime(nextProgram.start) + " - " + formatEpgTime(nextProgram.stop);
		} else {
			nextProgTitleRef.current.innerHTML = "";
			nextProgTimeRef.current.innerHTML = "";
		}

	}, [epgData]);

	const playNextVideo = useCallback(() => {

		dispatch(setVideo({
			video: nextVideo.id,
			carouselId: nextVideo.carousel
		}));

	}, [nextVideo, dispatch]);

	useEffect(() => {

		getEpg();

		props.updateElem(playBtnRef.current);

		const onPlaying = () => setIsPlaying(true);
		const onPause = () => setIsPlaying(false);

		const onVideoChanged = () => {
			playersCenter.addEvents([
				["playing", onPlaying],
				["pause", onPause]
			])
		};

		onVideoChanged();

		// Run "onVideoChanged" function again after player got destroyed due to change of the content type [video <--> audio"]
		playersCenter.EventBus.add("VIDEO_CONTENT_TYPE_CHANGED", onVideoChanged);

		return () => {
			playersCenter.removeEvents([
				["playing", onPlaying],
				["pause", onPause]
			])

			playersCenter.EventBus.remove("VIDEO_CONTENT_TYPE_CHANGED", onVideoChanged);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVideo]);

	useEffect(() => {
		const onVideoEnded = () => {
			playersCenter.addEvents([
				["ended", playNextVideo]
			]);
		}

		onVideoEnded();

		// Run "onVideoEnded" function again after player got destroyed due to change of the content type [video <--> audio]
		playersCenter.EventBus.add("VIDEO_CONTENT_TYPE_CHANGED", onVideoEnded);

		return () => {
			playersCenter.removeEvents([
				["ended", playNextVideo]
			]);

			playersCenter.EventBus.remove("VIDEO_CONTENT_TYPE_CHANGED", onVideoEnded);
		}

	}, [nextVideo, playNextVideo])

	useEffect(() => {
		props.updateElem(playBtnRef.current, props.isActive)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isActive])

	const enter = () => {

		const elem = props.getElem();
		const action = dataAttr(elem, "action");

		switch (action) {
			case "togglePlay": playersCenter.controller("togglePlay"); break;
			case "playNext": playNextVideo(); break;
			case "showEpg": showEpg(); break;
			default: break;
		}
	}

	const onMouseOver = useCallback(({ currentTarget }) => {
		props.onMouseOver({ currentTarget: currentTarget.parentElement });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useKeyHandler({
		keys: { right: props.next, left: props.prev, enter },
		isActive: props.isActive,
		dependency: [nextVideo]
	})

	const onPlayerRowClicked = useFunction(enter);

	return (
		<div className={ "controls " + (props.isActive ? " row_active " : "") + (!epgData ? " no_epg " : "") }>

			{ epgData && <div className='controls_epg_parent' ref={ epgParentRef } tabIndex={ -1 } aria-label="show epg" data-action="showEpg">
				<div className='controls_epg'
					role="button"
					onMouseOver={ onMouseOver }
					onClick={ onPlayerRowClicked }
				>
					<div className='epg_info' >
						<div className='epg_title' ref={ currentProgTitleRef }></div>
						<div className='epg_time' ref={ currentProgTimeRef }></div>
					</div>
					<div className='epg_info' >
						<div className='epg_title' ref={ nextProgTitleRef }></div>
						<div className='epg_time' ref={ nextProgTimeRef }></div>
					</div>
				</div>
			</div> }

			<div className="controls_item" ref={ playBtnRef } data-action="togglePlay" tabIndex={ -1 } aria-label={ isPlaying ? "pause video" : "play video" }>
				<div className="playBtn centerlize video_UI_color_unactive "
					role="button"
					onMouseOver={ onMouseOver }
					onClick={ onPlayerRowClicked }
					data-playing={ isPlaying }
				>
					{ isPlaying ? <PauseIcon /> : <PlayArrowIcon /> }
				</div>
			</div>

			<NextVideo mouseOver={ onMouseOver } nextVideo={ nextVideo } onClick={ onPlayerRowClicked } />

		</div>
	)
}

export default withControlRow(React.memo(PlayRow))