import { GET } from "Services/requests/get";
import Parser from "utils/parser";
/**
 * Module to send beconds. generate player beacons and sending them.
 * 
 * @class
 */
class Beacons {
    constructor () {
        /**
         * old beacon
         */
        this.beaconUrl = "";

        /**
         * The session beacon which only send once per session
         */
        this.sessionBeacon = "";

        /**
         * Beacon which will send for every event (new version for beaconUrl)
         */
        this.videoBeacon = "";

        /**
         * Copy of videoBeacon but with video macros changed
         */
        this.inVideoSession = "";

        /**
         * Copy of beaconUrl but with video macros changed
         */
        this.oldVideoSession = "";
    }

    init (urls) {
        this.beaconUrl = urls.beaconUrl;
        this.sessionBeacon = urls.beaconSession;
        this.videoBeacon = urls.beaconVideo;

        // start session
        this.sendEvent({ "[EVENT_ID]": 24 }, "session");
    }

    /**
     * Generating video beacon whlie changing video related macros.
     * 
     * @param {object} video The video object
     */
    generateVideoSession (video) {

        let carouselId = video.carouselId;

        let pageId = "";

        let selectedMenu = document.querySelector(".menu-item.selected");

        if (selectedMenu) pageId = selectedMenu.getAttribute("data-id");

        if (!carouselId) {

            let videoElement = document.querySelector("[data-video-id='" + video.id + "']");

            if (videoElement && videoElement.parentElement) {
                carouselId = videoElement.parentElement.getAttribute("data-carousel-id");
            }

        }

        const macros = {
            "[CONTENT_SESSION_ID]": Date.now() + Math.floor(100000 + Math.random() * 900000),
            "[VIDEO_CONTENT_ID]": video.id,
            "[VIDEO_TITLE]": video.title,
            "[PLAYLIST_ID]": carouselId,
            "[CATEGORY_ID]": undefined,
            "[PAGE_ID]": pageId,
        };

        this.inVideoSession = Parser.parse(this.videoBeacon, macros, false);
        this.oldVideoSession = Parser.parse(this.beaconUrl, macros, false);
    }

    /**
     * Decide what beacons to fire.
     * 
     * @param {string} type Type of list of urls to fire.
     * 
     * @returns {array} List of beacons urls to fire.
     */
    urlsToFire (type) {
        switch (type) {
            case "session": return [this.sessionBeacon];
            case "video": return [this.inVideoSession, this.oldVideoSession];
            default: return [this.beaconUrl, this.videoBeacon];
        }
    }

    /**
     * Sends beacon event.
     * 
     * @param {object} macros Additonal macros related to the event
     * @param {string} type which urls to fire (depends where we are sending the beacon)
     * 
     * @async
    */
    async sendEvent (macros = {}, type) {
        // console.log("sendEvent", { macros, type })
        const urls = this.urlsToFire(type);

        for (const beaconUrl of urls) {
            const _beacon = Parser.parse(beaconUrl, macros); // get temp macros and remove all unused macros
            this.fireBeacon(_beacon);
        };
    }

    /**
     * Sends error beacon.
     * 
     * @param {object} moreMacros Additonal macros related to the failed event
     * @param {string} type which urls to fire (depends where we are sending the beacon)
     * 
     * @async
    */
    async sendError (moreMacros = {}, type) {
        const urls = this.urlsToFire(type);

        for (const beaconUrl of urls) {
            const _beacon = Parser.parse(beaconUrl, moreMacros); // get temp macros and remove all unused macros
            this.fireBeacon(_beacon);
        };
    }

    /**
     * Fire the beacon url.
     * 
     * @param {string} url URL to fire
     * 
     * @async
     */
    async fireBeacon (url) {
        if (!url) return;

        if (!navigator.sendBeacon || url.includes("trk.playitviral.com/video/stats/ping.php")) {
            return GET(url);
        }

        navigator.sendBeacon(url);
    }
}

export default new Beacons();

`
Castify App Beacons

START_VIDE0 = "29" - On starting video
PLAY_VIDEO = "21" - On playing video
PAUSE_VIDEO = "22" -  On pausing video
VIDEO_END = "32" - On video end gracefully

CAROUSEL_CLICK = "23" - On clicking/selecting video
APP_OPEN = "24" - On opening the app on home page
EXIT_APP = "25" - On app exit
CONTENT_SEARCH = "41" - On searching the a video content - Start shooting from the 3rf letter, Here the event_value is is the search keyword

AD_BREAK_OPPORTUNITY = "31" - Shoot when its time for and ad break
AD_REQUEST = "26" - On requesting ads from the ad server

AD_BREAK_STARTED = "43" - On getting ads and starting to show them
AD_IMPRESSION = "27" - When ad start showing
AD_COMPLETE = "28" - When ad has finished showing
AD_BREAK_COMPLETED = "44" - When all ads in an ad break have finished showing

ERROR = "30" - On any error on the app
AD_ERROR = "33" - On an ad error
PAGE_CLICK = "40" - When a user visits any page. Here event_value is the page name
VIDEO_STOPPED = “45“ - This tracks if the video stopped without  ending gracefully. 
    So basically it's the reason why the video ended forcefully. 
    The event_value could be one of the following:
    
    1 user_back, 
    2 stream_error, 
    3 next video play,
    4 other video select, 
    5 app exit, 
    6 video_error, 
    7 app_crash
`