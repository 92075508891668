import React from 'react'
import { useSelector, shallowEqual } from "react-redux";
import "./hubItem.css";

export default function HubItem(props) {

  const { graphic } = useSelector(({app}) => ({
    graphic: app.graphic
  }), shallowEqual);

  return (
    <div className="hub_item_inner centerlize">
      <img src={props.item.image || graphic.appLogo} alt="" />
      <div className="hubItemTitle cutLines">
        <p>{props.item.title}</p>
      </div>
    </div>
  )
}
