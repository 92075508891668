import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';

// services
import { KeyHandlerCode } from 'data/constants';
import useKeyHandler from 'hooks/useKeyHandler.js';
import { navigateDown, navigateLeft, navigateRight, navigateUp } from './navigation.js';
import { setKeyHandler, setPopupType } from 'Services/redux/app/actions.js';
import { dataAttr } from 'utils/utiliesFunctions';
import WithKeyHandler from 'HOC/WithKeyHandler.js';
import { data } from "./data.js";

import "./keyboard.css";
import ExitPopUp from 'components/popups/exitPopup/ExitPopUp.js';

function Keyboard (props) {

    const dispatch = useDispatch()
    const [keyType, setKeyType] = useState("letter");
    const keyboardRef = useRef();
    const inputRef = useRef();

    const writeWithKeyboard = useCallback((action) => {

        switch (action) {
            case "clear":
                inputRef.current.innerHTML = "";
                props.handleInputChange(inputRef.current.innerText);
                break;
            case "backspace":
                back();
                break;
            case "search":
                goToGrid();
                break;
            default: //type
                inputRef.current.innerHTML += action;
                props.handleInputChange(inputRef.current.innerText);
                break;
        }

    }, [dispatch, props]);

    const back = () => {

        if (!inputRef.current.innerHTML) return dispatch(setKeyHandler(KeyHandlerCode.MENU));

        let newValue = inputRef.current.innerHTML.slice(0, -1);

        inputRef.current.innerHTML = newValue;

        props.handleInputChange(newValue);

    }

    const goToGrid = () => {

        let movingDiv = document.getElementsByClassName("movingDiv")[0];

        if (movingDiv && movingDiv.children.length > 0) {
            dispatch(setKeyHandler(KeyHandlerCode.GRID));
        } else {
            dispatch(setKeyHandler(KeyHandlerCode.MENU));
        }

    }

    useEffect(() => {
        const mobileRemoteKeyDown = (event) => {
            switch (event.keyCode) {
                case 8:
                    console.log("backspace")
                    // writeWithKeyboard("backspace")
                    break;
                default:
                    if (
                        event.keyCode === 32 || event.keyCode === 92 ||
                        (event.keyCode >= 44 && event.keyCode <= 90) ||
                        (event.keyCode >= 160 && event.keyCode <= 171) ||
                        (event.keyCode >= 174 && event.keyCode <= 176) ||
                        (event.keyCode >= 219 && event.keyCode <= 222)
                    )
                        writeWithKeyboard(event.key)
                    break;
            }
        }
        if (window.settings.platform === "vizio")
            window.addEventListener("keydown", mobileRemoteKeyDown);

        return () => {
            if (window.settings.platform === "vizio")
                window.removeEventListener("keydown", mobileRemoteKeyDown);
        }
    }, [writeWithKeyboard])

    useEffect(() => {

        if (props.isActive) {
            const elem = keyboardRef.current.firstChild;
            elem.classList.add("active");
            elem.focus();
        }

    }, [props.isActive])

    const enter = () => {

        const action = dataAttr(document.querySelector(".btn.active"), "action");

        switch (action) {
            case "symbols":
                setKeyType(state => state === "number" ? "letter" : "number");
                break;
            default: //type
                writeWithKeyboard(action);
        }

    }

    useKeyHandler({
        keys: {
            down: () => {
                const res = navigateDown();
                res === "goToGrid" && goToGrid();
            },
            right: navigateRight,
            left: navigateLeft,
            up: navigateUp,
            enter,
            back
        },
        isActive: props.isActive
    });

    const keyboardMemo = useMemo(() => {
        console.log("keyboardMemo", data)
        return (

            data.keys.map((item, index) => (
                <div
                    key={index}
                    data-index={index}
                    className={`btn ${ item.className || "" }`}
                    data-action={item.action || item[keyType]}
                    tabIndex={-1}
                    aria-label={item.label || item[keyType]}
                >
                    <div className="centerlize">
                        {item.display ? <item.display /> : item[keyType]}
                    </div>
                </div>
            ))
        )
    }, [keyType])

    return (
        <div className="keyBoardContainer">
            <div className="searchFields" ref={inputRef} style={{ borderColor: `3px solid ${ props.color }` }} />

            {props.isActive && (
                <div className="keyboardWrapper">
                    <div className="keyboard" ref={keyboardRef}>
                        {keyboardMemo}
                    </div>
                </div>
            )}

        </div>
    )
}

export default WithKeyHandler(Keyboard, KeyHandlerCode.KEYBOARD)