import useFunction from 'hooks/useFunction';
import useKeyHandler from 'hooks/useKeyHandler'
import React from 'react'

export default function ActionBtn(props) {

  const itemSelected = () => {
    props.onItemSelected(props.option.action);
  }

  useKeyHandler({
    keys: {
      enter: itemSelected
    },
    isActive: props.isActive
  });

  const toggleStateOnClick = useFunction(itemSelected);

  return (
    <>
      <p className="item_title">{props.option.label}</p>

      <div className="action_item_bg centerlize" role="button" onClick={toggleStateOnClick}>
        <props.option.Icon />
      </div>
    </>
  )
}
