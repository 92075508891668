import React from 'react';
import "./PlayerWindowTime.css";

function PlayerWindowTime(props, ref) {

	return (
		<div className="progressHoverContainer centerlize" ref={ref}>
			<span>{props.newTime}</span>
		</div>
	)
};

export default React.memo(React.forwardRef(PlayerWindowTime));