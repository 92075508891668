import React, { useEffect } from 'react'
import useKeyHandler from 'hooks/useKeyHandler'
import { useDispatch } from 'react-redux';
import { setKeyHandler } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';
import MenuSubPage from './parts/MenuSubPage';

// pages
import HomePageDefault from '../../pages/detailedPage/DetailedPage';
import MultiCategories from '../multiCategories/MultiCategories';

const settings = {
  horizntalEdge: KeyHandlerCode.HUB_MENU
}

export default function HubSubPages(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    document.querySelector(".menu-section").style.zIndex = 0;
  }, []);

  const back = (event) => {
    document.querySelector(".menu-section").style.zIndex = 21;
    props.setActiveContainer(null);
    dispatch(setKeyHandler(KeyHandlerCode.GRID));
    if (event) {
      event.stopImmediatePropagation();
      event.stopPropagation();
    }
  }

  const renderPage = () => {
    switch (props.activeContainer.type) {
      case "multiCategories": return <MultiCategories container={props.activeContainer} type="hub-page" settings={settings} />
      case "detailed": return <HomePageDefault container={props.activeContainer} type="hub-page" settings={settings} />
      default: return;
    }
  }

  useKeyHandler({
    keys: {
      back
    },
    isActive: true
  })

  return (
    <div className="hubSubPage ">

      {renderPage()}

      <MenuSubPage closePage={back} />
    </div>
  )
}
