import React, { useEffect, useMemo } from 'react'
import HomePageDefault from '../detailedPage/DetailedPage';
import MultiCarousel from '../multiCategories/MultiCategories';
import Hub from '../hub/Hub';
import { KeyHandlerCode } from 'data/constants';
import { useSelector, shallowEqual } from "react-redux";
import './home.css';

function HomePage(props) {
	const { hasMenu, homePageContainer } = useSelector(({ app, entities }) => ({
		hasMenu: app.graphic.hasMenu,
		homePageContainer: entities.pages["homePage"]
	}), shallowEqual);

	useEffect(() => {
		props.onHomePageMounted();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const settings = useMemo(() => {
		return {
			horizntalEdge: hasMenu ? KeyHandlerCode.MENU : ""
		}
	}, [hasMenu]);

	const returnHomePage = () => {
		switch (homePageContainer.type) {
			case "multiCategories": return <MultiCarousel type="home" container={homePageContainer} settings={settings} />
			case "hub": return <Hub type="home" container={homePageContainer} settings={settings} />
			case "home": return <HomePageDefault type="home" container={homePageContainer} settings={settings} />
			default: throw new Error(`Unsupported container type as homepage: ${homePageContainer.type}`)
		}
	}

	return homePageContainer && returnHomePage();
}

export default React.memo(HomePage);