export class Pal {
  constructor() {
    this.nonceLoader = new window.goog.pal.NonceLoader();
    this.nonceManager = null;

    this.generateNonce()
  }

  /**
   * Generate a nonce
   * 
   * @async
   */
  async generateNonce() {
    this.nonceManager = null;

    const request = new window.goog.pal.NonceRequest();
    request.adWillAutoPlay = true;
    request.adWillPlayMuted = false;
    request.continuousPlayback = true;
    request.playerType = "castify-player"
    request.playerVersion = "1.0.0";
    request.ppid = "";
    request.videoHeight = window.screen.height;
    request.videoWidth = window.screen.width;
    request.omidPartnerName = "castify";

    try {
      this.nonceManager = await this.nonceLoader.loadNonceManager(request);
    }
    catch (err) {
      console.log(`Error generating nonce: ${err}`)
    }
  }

  /**
   * Get nonce.
   * 
   * @returns {string} Nonce string
   */
  getNonce() {
    if (!this.nonceManager) return;

    return this.nonceManager.getNonce();
  }

  /**
   * Notify PAL for ad impression
   */
  onImpression() {
    if (!this.nonceManager) return;

    this.nonceManager.sendAdImpression();
  }

  /**
   * Notify PAL for playback end
   */
  onPlaybackEnd() {
    if (!this.nonceManager) return;

    this.nonceManager.sendPlaybackEnd();
  }
}