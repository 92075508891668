import React, { useState, useEffect, useRef, useCallback } from 'react'
import DetailedView from './DetailedView';
import CarouselManager from '../../components/carousel/CarouselManager';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { CarouselManagerTypes } from 'data/constants';
import { setKeyHandler, setPopupType } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';
import ExitPopUp from 'components/popups/exitPopup/ExitPopUp';

export default function DetailedPage (props) {
	const dispatch = useDispatch();

	const { backgroundImg, backgroundColor, hasMenu } = useSelector(({ app }) => ({
		backgroundImg: props.container.graphic.page.page_background_image || app.graphic.backgroundImg,
		backgroundColor: props.container.graphic.page.page_background_color || app.graphic.backgroundColor,
		hasMenu: app.graphic.hasMenu
	}), shallowEqual);

	const [activeVideo, setActiveVideo] = useState({});
	const bg = useRef();

	useEffect(() => {
		Object.assign(bg.current.style, {
			backgroundImage: `url('${backgroundImg}')`,
			backgroundColor: backgroundColor
		});

		dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
	}, [backgroundImg, backgroundColor, dispatch]);

	// when we reached the top left of the carousel and prssing left again
	const onCarouselReachedLeft = useCallback(() => {

		console.log('onCarouselReachedLeft');

		// old code
		// const newKeyhandlerCode = props.settings.horizntalEdge || hasMenu ? KeyHandlerCode.MENU : null;
		// if (newKeyhandlerCode)
		// dispatch(setKeyHandler(newKeyhandlerCode));
		//

		// new code
		if (props.type == "hub-page") {
			dispatch(setKeyHandler(KeyHandlerCode.HUB_MENU));
		} else {

			let existMenu = document.getElementsByClassName("menu-float-ui").length > 0;
			if (existMenu) dispatch(setKeyHandler(KeyHandlerCode.MENU));
			else dispatch(setPopupType(ExitPopUp));

		}

	}, [dispatch, props.settings.horizntalEdge]);

	const setActiveCarouselParent = useCallback(video => {
		setActiveVideo(video);
	}, []);

	return (
		<div className="detailed_background fullScreen" ref={ bg }>
			<DetailedView selectedVideo={ activeVideo } />

			<CarouselManager
				content={ props.container.content }
				carouselManagerType={ CarouselManagerTypes.HOME }
				type={ props.type }
				carouselHeader="h2"
				reachedLeft={ onCarouselReachedLeft }
				onItemSelected={ setActiveCarouselParent }
			/>
		</div>
	)
}