import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { setPopupType } from 'Services/redux/app/actions';

export default function usePopup() {
  const dispatch = useDispatch();

  const displayPopup = useCallback((Component, props) => {
    dispatch(setPopupType(Component, props));
  }, [dispatch]);

  const closePopup = useCallback(() => {
    dispatch(setPopupType(null));
  }, [dispatch]);

  return { displayPopup, closePopup };
}