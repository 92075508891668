import React, { useCallback, useRef } from 'react'

function Image({
  src,
  className = "",
  defaultImage,
  layout,
  onLoad,
  onError,
  width,
  height
}) {
  const imgRef = useRef();

  const imageError = useCallback(({ currentTarget }) => {
    imgRef.current.removeEventListener("error", imageError);
    currentTarget.src = defaultImage;
    onError && onError();
  }, [defaultImage, onError]);

  const imageLoaded = useCallback(() => {
    onLoad && onLoad();
  }, [onLoad]);

  return (
    <img
      className={`appImage ${className}`}
      src={src}
      alt=""
      onError={imageError}
      onLoad={imageLoaded}
      ref={imgRef}
      data-layout={layout}
      width={width}
      height={height}
    />
  )
}

export default React.memo(Image);

