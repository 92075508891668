import React from 'react'

function PageHeader(props) {
	return (
		<header className="page-header" style={{ color: props.color }}>
			<h1 className="page-title">
				{props.appName}
				<span className="borderHeader" style={{ backgroundColor: props.color }} />
				<span className="page_header_title" id="pageTitle">
					{props.title}
				</span>
			</h1>
		</header>
	)
}

export default React.memo(PageHeader)
