export default class BrighData {

    constructor (tag) {
        this.name = "brighData";
        this.type = "brighData";
        this.tag = tag;
        this.status = null;
    }

    init (data) {

        let { app_name, app_logo } = data;

        return new Promise((resolve, reject) => {

            if (window.webOS && window.webOS.platform && window.webOS.platform.tv) {

                let script = document.createElement('script');

                script.src = './brd_api.js';

                script.onload = () => {

                    try {

                        if (!window.brd_api) return reject('BRD API not found');

                        this.brd_api = window.brd_api;

                        let api_settings = {
                            agree_btn: 'Remove Ads',
                            disagree_btn: 'I prefer to see ads',
                            opt_out_instructions: ' ',
                            app_name,
                            app_logo,
                            benefit_txt: 'Accepting this form will allow you to enjoy your preferred content without being interrupted by ads',
                            // skip_consent: true,
                            on_status_change: () => {

                                try {
                                    this.status = this.brd_api.get_status();
                                    if (this.statusChanged && typeof this.statusChanged == 'function') this.statusChanged(this.status);
                                    console.log('status change', this.status);
                                } catch (e) {
                                    console.log(e);
                                }

                            },
                            on_dialog_shown: () => {
                                // var qr_link = document.querySelector(".qr_link");
                                // if (qr_link) qr_link.style.backgroundImage = "url(src/img/qr.png)";
                            },
                            on_dialog_closed: () => { },
                            iframe: false,
                        };

                        this.brd_api.init(api_settings, {
                            on_failure: (message) => {
                                reject(message);
                            },
                            on_success: () => {
                                this.status = this.brd_api.get_status();
                                resolve();
                            },
                        });

                    } catch ({ message }) {
                        reject(message);
                    }

                }

                script.onerror = () => {
                    reject('BRD API script load error');
                }

                document.head.appendChild(script);

            } else {
                reject("BRD API can't run on this platform");
            }

        });

    }

    load () {

        return new Promise((resolve, reject) => { resolve(this); });

    }

    enable () {

        if (!this.brd_api) return console.log('BRD API not found');

        try {

            this.brd_api.show_consent({
                on_failure: () => { console.log('show_consent failure'); },
                on_success: () => { console.log('show_consent sucess'); },
            });

        } catch (e) {
            console.log(e);
        }

    }

    disable () {

        if (!this.brd_api) return console.log('BRD API not found');

        try {

            this.brd_api.opt_out({
                on_failure: () => { console.log('opt_out failure'); },
                on_success: () => { console.log('opt_out sucess'); },
            });

        } catch (e) {
            console.log(e);
        }

    }

};