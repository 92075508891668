import React from 'react';

 function BlueBox({ text, hasNoItems = false, color }) {
	const createMarkup = () => {
		return {__html: text};
	}

	return (
		<div className={"BlueBox" + (hasNoItems ? " witout_items" : "")} style={{color}} dangerouslySetInnerHTML={createMarkup()} />
	)
}

export default React.memo(BlueBox)