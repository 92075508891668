import React, { useEffect, useState, useRef, useMemo } from 'react'
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { setKeyHandler } from "Services/redux/app/actions";
import { KeyHandlerCode } from 'data/constants';
import './screenSaver.css'

let interval, counter, randomNum, goingLeft = false;

const makeSelectVideos = () =>
	createSelector(
		videos => videos,
		(allVideos) => {
			const screenSaverVideos = [];
			const keys = Object.keys(allVideos);

			const getVideo = (video) => ({
				id: video.id,
				thumbnail: video.video_cover || video.thumbnail,
				title: video.title,
			});

			if (keys.length <= 20) {
				return keys.map(videoId => getVideo(allVideos[videoId]))
			}

			while (screenSaverVideos.length < 20) {
				const index = Math.floor((Math.random() * (keys.length - 1)));
				const randomKey = keys[index];
				const video = getVideo(allVideos[randomKey]);
				keys.splice(index, 1); // remove the key from array so we will not find it again

				screenSaverVideos.push(video);
			}

			return screenSaverVideos;
		}
	)

export default function ScreenSaver ({ data }) {

	const selectVideos = useMemo(makeSelectVideos, []);

	const dispatch = useDispatch();

	const { appLogo, videos } = useSelector(({ app, entities }) => ({
		appLogo: app.graphic.appLogo,
		videos: selectVideos(entities.videos)
	}), shallowEqual);

	const [activeItem, setActiveItem] = useState({});

	const screenSaverContainer = useRef();
	const screenSaverImg = useRef();

	useEffect(() => {

		createItem();
		dispatch(setKeyHandler(KeyHandlerCode.SCREEN_SAVER));

		return () => {
			clearInterval(interval);
			clearInterval(counter);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const changeDirection = () => {
		goingLeft = !goingLeft;
		const position = goingLeft ? -window.innerWidth * 0.2 : 0;
		screenSaverImg.current.style.transform = `translate3d(${position}px,0,0)`
	}

	const createItem = () => {
		randomNum = videos.length === 1 ? 0 : generateRandom();
		setActiveItem(videos[randomNum]);
	}

	const onImageLoad = () => {
		screenSaverContainer.current.classList.add("imageLoad");
		changeDirection();
		screensaverCounter();
	}

	const imageFailed = () => {
		createItem()
		changeDirection()
		screensaverCounter();
	}

	const generateRandom = () => {
		const createRandom = Math.floor(Math.random() * videos.length);
		return createRandom === randomNum ? generateRandom() : createRandom // if the created number equals to the active number run this function again
	}

	const hideElements = () => {
		screenSaverContainer.current.classList.remove("imageLoad");
		const getFunRef = videos.length > 1 ? createItem : onImageLoad;
		setTimeout(getFunRef, 1000);
	}

	const screensaverCounter = () => {
		clearInterval(counter);
		let sec = 0;
		counter = setInterval(() => {
			++sec;
			if (sec === 14 && videos.length > 1) hideElements();
		}, 1000);
	}

	return (
		<div className="screenSaverContainer fullScreen">
			<div className="screen_saver_inner" ref={ screenSaverContainer }>
				<img ref={ screenSaverImg } src={ activeItem.thumbnail } id="screenSaverImg" onLoad={ onImageLoad } onError={ imageFailed } alt="screensaver" />

				<img className="powerBy" src={ data.watermark_image } alt="castify" />

				<div className="screenSaver_bottomLine">
					<div className="textContainer">
						<h2 className="screenSaver-title">{ activeItem.title }</h2>
					</div>
					<img className="screenSaver-logo" src={ appLogo } alt="app logo" />
				</div>
			</div>

		</div>
	)
}
