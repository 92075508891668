import { applyMiddleware, combineReducers } from 'redux';
import { createStore } from "redux";
import appReducer from './app/appSlice';
import entitiesReducer from './entities/entitiesSlice';
import videoReducer from './video/videoSlice';
import thunk from 'redux-thunk'

const rootReducer = combineReducers({
	app: appReducer,
	entities: entitiesReducer,
	video: videoReducer
});

export const store = createStore(rootReducer, applyMiddleware(thunk));