/**
 * Save player settings like useage of hls.js, the media type, etc...
 * 
 * @class
 */
export default class Config {
  constructor ({ mediaType, device }) {
    this._configs = {
      mediaType,
      device, // audio || video
      currentPlayerType: "", // "normal | hls_extension",
      useHls: ["vizio", "emulator"].includes(device)
    }
  }

  /**
   * Change config values.
   * 
   * @param {string} configName The field to retrieve.
   */
  getConfig (configName) {
    return this._configs[configName];
  }

  /**
   * Change config values.
   * 
   * @param {object} newConfig The config value to add/edit.
   * @returns Returns the updated config object.
   */
  setConfig (newConfig) {
    return Object.assign(this._configs, newConfig);
  }
}