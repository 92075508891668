import Image from 'components/Widgets/images/Image';
import React from 'react';
import "./movieCarousel.css";

function MovieCarousel({ title, img }) {
	return (
		<>
			<div className="movie_inner">
				<div className="ratio_keep">
					<Image layout="cover" className="movieCarousel-img" src={img} alt={title} width="251" height="367" />
				</div>
			</div>

			<span className="movie-caro-title cutLines">
				{title}
			</span>
		</>
	)
}

export default React.memo(MovieCarousel);