import React from 'react';
import './topCarouselstory.css';

function CarouItemStory (props) {

	const createStyle = () => {

		let style = {};

		if (props.item.graphic && props.item.graphic.text_color) {
			style.color = props.item.graphic.text_color;
		}

		return style;

	}

	return (
		<>
			<div className="cat-item">
				<img className="cat-item-img" src={props.item.image} onError={props.onImageError} alt={props.item.title} />
			</div>
			<h2 className="cat-title" style={createStyle()} >
				{props.item.title}
			</h2>
		</>
	)
}

export default React.memo(CarouItemStory)