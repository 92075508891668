import React from 'react';

export default function CarouItemGame(props) {
	return (
		<>
			<div className="cat-item"
				onMouseOver={props.mouseItemHandler}
				onClick={props.clickHandler}
				data-index={props.index}
			>
				<div className="cat-item-inner" style={{ backgroundImage: `url(${props.item.image})` }}>
					{/* <img src={props.item.image} /> */}
				</div>
			</div>
			<div className="cat-title-container">
				<div className="cat-title">
					{props.item.title}
				</div>
				<div className="cat-title-icon">
					<img src={"https://cdn.castify.ai/files/app/category_bottom_icon.svg"} height="50px" alt="game" />
				</div>
			</div>
		</>
	)
}
