import React, { useCallback, useRef } from 'react';

const withControlRow = (Component) => {

	const WithStateComponent = (props) => {
		const activeElem = useRef();

		const onMouseOver = useCallback(({ currentTarget }) => {
			updateElem(currentTarget)
			props.setRowPosition && props.setRowPosition(props.rowIndex);

			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [])

		const updateElem = useCallback((elem, setFocus = true) => {
			activeElem.current && activeElem.current.classList.remove("active");
			activeElem.current = elem;
			activeElem.current.classList.add("active");
			setFocus && activeElem.current.focus();
		}, []);

		const next = useCallback(() => {
			const nextElement = activeElem.current.nextElementSibling;
			if (!nextElement) return;

			updateElem(nextElement);

			return nextElement
		}, [updateElem])

		const prev = useCallback(() => {
			const prevElement = activeElem.current.previousElementSibling;
			if (!prevElement) return;

			updateElem(prevElement);

			return prevElement
		}, [updateElem])

		const getElem = useCallback(() => activeElem.current, [])

		return (
			<Component
				onMouseOver={onMouseOver}
				updateElem={updateElem}
				getElem={getElem}
				next={next}
				prev={prev}
				{...props}
			/>
		)
	}
	return React.memo(WithStateComponent);
}
export default withControlRow
