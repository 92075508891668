import React from 'react'

export default function Server_err() {
	return (
		<>
			<p>
				The system is under maintenance. <br />
				Please check in a few minutes
			</p>

			<img id="castifyImg" src="https://cdn.castify.ai/files/app/castify_watermark.png" alt="castify" />
		</>
	)
}
