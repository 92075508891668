import React from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

function VideoActionButton({ onMouseOver }, ref) {

	return (
		<div className="video_option_btn centerlize video_UI_color_unactive" onMouseOver={onMouseOver} ref={ref} tabIndex={-1} aria-label="options, navigate to right" data-options="false">
			<MoreVertIcon />
		</div>
	)
}

export default React.memo(React.forwardRef(VideoActionButton));