import React from 'react';
import Loader from 'components/Widgets/loaders/Loader';
import './streamLoader.css'

function StreamLoader(_, ref) {
	return(
		<div ref={ref} className="stream_loader" id="videoLoader">
			<Loader />
		</div>
	)
}

export default React.memo(React.forwardRef(StreamLoader))
