import React from 'react';

export default function Network_err() {
	return (
		<>
			<p>
				Oops no connection! <br />
				Please check your connection and try again
			</p>

			{/* <img id="castifyImg" src="https://cdn.castify.ai/files/app/castify_watermark.png" alt="castify" /> */}
		</>
	)
}
