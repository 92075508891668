import React, { useEffect, useCallback, useRef, useState } from 'react';
import VideoActionButton from '../../controls/VideoActionButton';
import withControlRow from 'HOC/withControlRow';
import useKeyHandler from 'hooks/useKeyHandler';
import { dataAttr } from 'utils/utiliesFunctions';
import PlayerInterface from 'Services/Player/playersCenter';
import './actions.css';
import { useDispatch } from 'react-redux';
import { addVideoToCarousel, removeVideoFromCarousel } from 'Services/redux/entities/actions';
import { getLocalStorage, setLocalStorage } from 'utils/storage/localStorage';
import ActionBtn from '../types/ActionBtn';
import ActionToggle from '../types/ActionToggle';
import ActionOptions from '../types/ActionOptions';

function ActionRow ({ isActive, downParent, onMouseOver, playerOption, updateElem, next, prev, displayArticle, exitVideo }) {
	const dispatch = useDispatch();
	const [indexOfOpenItem, setIndexOfOpenItem] = useState(null);

	const buttonRef = useRef();

	useEffect(() => {
		if (isActive) {
			updateElem(buttonRef.current)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isActive])

	const onItemSelected = useCallback((action, value) => {
		switch (action) {
			case "back": exitVideo(false); break;
			case "replay": PlayerInterface.controller("replay"); break;
			case "subtitles": PlayerInterface.action("setTrack", value); break;
			case "article": displayArticle(); break;
			case "addToList": if (window.clientPlaylists.myList) toggleMyList(); break;
			default: console.log(`Unknown action: ${action}`); break;
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const toggleMyList = () => {
		const { id } = PlayerInterface.action("getVideo");
		const myList = getLocalStorage("myList", {});

		// if the video is already in
		if (myList[id]) {
			setLocalStorage({
				type: "myList/remove",
				value: id
			})
			dispatch(removeVideoFromCarousel("myList", id))
		} else {
			setLocalStorage({
				type: "myList/add",
				value: id
			})
			dispatch(addVideoToCarousel("myList", id))
		}
	}

	const displayActionsType = (option, index) => {
		const props = {
			option,
			isActive: isActive && indexOfOpenItem === index,
			downParent,
			onItemSelected
		};

		switch (option.type) {
			case "btn": return <ActionBtn { ...props } />
			case "toggle": return <ActionToggle { ...props } />
			case "list": return <ActionOptions { ...props } />
			default: console.log(`Unknown video option type: ${option.type}`); return null
		}
	}

	useKeyHandler({
		keys: {
			right: () => {
				const element = next();
				element && setIndexOfOpenItem(+dataAttr(element, "index"))
			},
			left: () => {
				const element = prev();
				element && setIndexOfOpenItem(+dataAttr(element, "index"))
			},
			down: (e) => {
				if (!playerOption[indexOfOpenItem] || !playerOption[indexOfOpenItem].options) downParent(e);
			},
			// enter
		},
		isActive: isActive,
		dependency: [indexOfOpenItem]
	});

	return (
		<div className="actions_top_container">
			<div className={ "actions_list list" + (isActive ? " row_active" : "") }>
				<VideoActionButton onMouseOver={ onMouseOver } ref={ buttonRef } />
				{ playerOption.map((item, index) => (
					<div key={ index } className="action_item list" onMouseOver={ onMouseOver } tabIndex={ -1 } data-index={ index }>
						{ displayActionsType(item, index) }
					</div>
				)) }
			</div>
		</div>
	)
}

export default withControlRow(React.memo(ActionRow))
