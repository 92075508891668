import { findVideoInCarousel } from "../reduxFunction";
import GlobalAnaltyics from "Services/analytics/globalAnaltyics"

/**
 * Open the player and set the carousels and find the next content.
 * 
 * @param {object} playerProperties 
 * @param {object} playerProperties.video Video object 
 * @param {Array} playerProperties.content List of carousels
 * @param {string} playerProperties.backTo What widget should be focused when exiting from the player 
 * @param {string} playerProperties.activeCarousel The active carousel which one of his video are currently playing
 * @param {object} playerProperties.nextVideo Next content object
 */
export function openVideoPlayer (playerProperties) {
	return {
		type: "video/init",
		payload: {
			selectedVideo: playerProperties.video,
			videoPlayerContent: playerProperties.content,
			keyHandlerBeforePlayer: playerProperties.backTo,
			activeCarousel: playerProperties.activeCarousel,
			nextVideo: playerProperties.nextVideo
		}
	};
}

/**
* Show / hide the player component
*
* @param {boolean} payload Should show or hide the player.
*/
export function setVideoVisibility (payload) {
	return {
		type: "video/set-video-visibility",
		payload
	};
}

/**
 * Set the current playing video
 * 
 * @param {object} payload Video object
 */
export function setSelectedVideo (payload) {

	return {
		type: "video/set-selected-video",
		payload
	};
}

/**
* Middleware to start the player and also find the next video
*
* @param currentVideoData.content Carouesel(s) to display in the video player
* @param currentVideoData.carouselId The Carousel we selected the video from (usefull to get the correct next video)
* @param currentVideoData.video The video id we want to play
* @param currentVideoData.backTo Key handler to back after player
*/
export function setOpenVideoPlayer (currentVideoData) {
	return (dispatch, getState) => {
		const { video } = currentVideoData
		const entities = getState().entities;

		// Check if this video id exist
		if (!entities.videos[video]) return;

		// Remove empty carouesls so we won't display it in the player page
		let videoCarousels = currentVideoData.content.filter(carouselId => entities.carousels[carouselId].videos.length);
		// Get the current playing carousel
		let currentCarousel = entities.carousels[currentVideoData.carouselId];

		// if currentVideoData.currentCarousel is empty try to find a carouesl by the video ID
		if (!currentCarousel) {
			const caroueslId = findVideoInCarousel(video);
			currentCarousel = entities.carousels[caroueslId];
			videoCarousels = [caroueslId]
		}

		// If we still didn't find carouesl, skip the find-next-video process
		if (!currentCarousel) {
			dispatch(openVideoPlayer({
				video,
				content: [],
				backTo: currentVideoData.backTo,
				nextVideo: { ...entities.videos[video], carousel: "" }
			}))

			return
		}


		// find the video position in active carousel, that wat we can get the next video
		const videoIndex = currentCarousel.videos.findIndex(videoId => videoId === currentVideoData.video);
		// get the new carouesl index if findIndex returns -1 we get the first carouesl (the same)
		const carouselIndex = videoCarousels.findIndex(id => id === currentVideoData.carouselId);

		const nextVideoId = currentCarousel.videos[videoIndex + 1];
		const nextCarouselId = videoCarousels[carouselIndex + 1] || videoCarousels[0];

		const newPos = {
			video: nextVideoId ? nextVideoId : entities.carousels[nextCarouselId].videos[0],
			carousel: nextVideoId
				? currentCarousel.entity_id
				: nextCarouselId
		};

		dispatch(openVideoPlayer({
			video: currentVideoData.video,
			content: videoCarousels,
			backTo: currentVideoData.backTo,
			nextVideo: { ...entities.videos[newPos.video], carousel: newPos.carousel }
		}));
	}
}

/**
* Middleware to update the current playing media and also find the next video 
*
*  @param {string} currentVideoData.carouselId Current carouesl ID
*  @param {string} currentVideoData.video Video ID we want to play
*/
export function setVideo (currentVideoData) {

	return (dispatch, getState) => {

		let state = getState();

		let { video } = state;

		let { carousels, videos } = state.entities;

		let carouselId = currentVideoData.carouselId;

		let videoId = currentVideoData.video;

		let content = video.videoPlayerContent;

		let caruselIndex = content.indexOf(carouselId);

		let videoIndex = carousels[carouselId].videos.indexOf(videoId);

		let nextVideoId = carousels[carouselId].videos[videoIndex + 1];

		if (!nextVideoId) {

			if (content.length > 1) {

				let nextCarouselIndex = caruselIndex + 1;

				carouselId = content[nextCarouselIndex] || content[0];

				if (!carousels[carouselId].videos.length || !carousels[carouselId].display) {

					for (let i = nextCarouselIndex; i < content.length; i++) {

						if (carousels[content[i]].videos.length && carousels[content[i]].display) {
							carouselId = content[i];
							break;
						}

					}

					if (!carousels[carouselId].videos.length || !carousels[carouselId].display) {

						for (let i = 0; i < content.length; i++) {

							if (carousels[content[i]].videos.length && carousels[content[i]].display) {
								carouselId = content[i];
								break;
							}

						}

					}

				}

				let nextVideoIndex = carousels[carouselId].videos.indexOf(videoId) + 1;

				nextVideoId = carousels[carouselId].videos[nextVideoIndex];

			} else {
				nextVideoId = carousels[carouselId].videos[0];
			}

		}

		dispatch(setSelectedVideo({
			video: videoId,
			nextVideo: { ...videos[nextVideoId], carousel: carouselId }
		}));

		// console.log("state", state);

		// const entities = getState().entities;
		// const videoCarousels = getState().video.videoPlayerContent;
		// const currentCarousel = entities.carousels[currentVideoData.carouselId];

		// console.log(currentVideoData, entities);

		// const videoIndex = currentCarousel.videos.findIndex(videoId => videoId === currentVideoData.video);
		// const carouselIndex = videoCarousels.findIndex(id => id === currentVideoData.carouselId);

		// const nextVideoId = currentCarousel.videos[videoIndex + 1];
		// const nextCarouselId = videoCarousels[carouselIndex + 1] ? videoCarousels[carouselIndex + 1] : videoCarousels[carouselIndex];

		// const newPos = {
		// 	video: nextVideoId ? nextVideoId : entities.carousels[nextCarouselId].videos[entities.carousels[nextCarouselId].videos.length - 1],
		// 	carousel: nextVideoId
		// 		? currentCarousel.entity_id
		// 		: nextCarouselId ? nextCarouselId : videoCarousels[0]
		// }

		// dispatch(setSelectedVideo({
		// 	video: currentVideoData.video,
		// 	nextVideo: { ...entities.videos[newPos.video], carousel: newPos.carousel }
		// }));

	}
}

/**
 * Middleware for selecting a video
 * 
 * @param {string} videoId Video ID
 */
export function clickVideoItem (videoId) {

	GlobalAnaltyics.sendEvent("carouselClick", videoId);

}