import PlatformEvents from "utils/eventsBus";

export default class Samsung {
	constructor () {
		this.name = "samsung";
		this.IFAType = "tifa";
		this.PlatformEvents = new PlatformEvents();
	}

	async initDevice () {
		this.getKeys();
		this.registerListeners();

		return {
			keys: {
				BACK: 10009,
				EXIT: 10182,
				TOGGLE_PLAY: 10252
			},
			deepLinkData: {
				video: this.getDeepLinkVideo(),
				source: "",
				carousel: ""
			},
			platformSettings: {
				animation: true,
				keyboard: false,
				cursor: true,
				encoding: "normal",
				subtitles: true,
				hlsHelp: false
			}
		}
	}

	registerListeners () {

		document.addEventListener("appcontrol", () => {

			const contentChangeEvent = this.PlatformEvents.get("contentChange");

			if (!contentChangeEvent) return;

			let video_id = this.getDeepLinkVideo();

			if (!video_id) return;

			// check if we registered event called "contentChange", if so send the path (will be the video id)
			for (const event of contentChangeEvent) {
				event(video_id);
			}

		});

	}

	getUUID = () => {
		try {
			return window.webapis.appcommon.getUuid()
		}
		catch {
			return null
		}
	}
	getDeviceDetailed = () => {
		try {
			return `version: ${window.webapis.network.getVersion()}`
		}
		catch {
			return null
		}
	}
	getAdsTracking = () => {
		try {
			return window.webapis.adinfo.isLATEnabled()
		}
		catch {
			return null
		}
	}
	getIDFA = () => {
		try {
			return window.webapis.adinfo.getTIFA()
		}
		catch {
			return null
		}
	}
	getIFAType = () => this.IFAType;

	getKeys = () => {
		const keys = [
			'MediaPlay',
			'MediaPause',
			'MediaStop',
			'MediaPlayPause',
			'ColorF0Red',
			'ColorF1Green',
			'ColorF2Yellow',
			'ColorF3Blue',
			'ChannelUp',
			'ChannelDown',
			'MediaRewind',
			'MediaFastForward',
			'0',
			'1',
			'2',
			'3',
			'4',
			'5',
			'6',
			'7',
			'8',
			'9'
		];
		window.tizen.tvinputdevice.registerKeyBatch(keys);
	}

	onDocumentHide = () => {
		window.tizen.application.getCurrentApplication().hide();
	}

	exitApp = () => {
		window.tizen.application.getCurrentApplication().exit();
	}

	getDeepLinkVideo = () => {
		const requestedAppControl = window.tizen.application.getCurrentApplication().getRequestedAppControl();

		if (!requestedAppControl) return;

		const appControl = requestedAppControl.appControl.data;

		console.log("appControl", appControl);

		for (const obj of appControl) {
			if (obj.key === "PAYLOAD") {
				const actionDataValues = JSON.parse(obj.value[0]).values;
				if (JSON.parse(actionDataValues).videoIdx) {
					let videoIdx = JSON.parse(actionDataValues).videoIdx;
					return videoIdx;
				}
			}
		}
	}

}
