import React, { useCallback } from 'react'
import WithPage from 'HOC/withPage';
import { KeyHandlerCode } from 'data/constants';
import Grid from 'components/videosGrid/Grid';
import Product from './product/Product';

function Store(props) {

	const renderItem = useCallback((item) => <Product item={item} />,[])

	return (
		<Grid content={props.page.items} renderItem={renderItem} class="storeItem" horizntalTiming=".5" />
	)
}


export default WithPage(Store, KeyHandlerCode.GRID);