import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { setKeyHandler, setActivePage, setFadeScreen, setActiveCarouselManager } from 'Services/redux/app/actions';
import useLanguage from 'hooks/useLanguage';
import useFunction from 'hooks/useFunction';
import useKeyHandler from 'hooks/useKeyHandler';
import WithKeyHandler from 'HOC/WithKeyHandler';

import { dataAttr } from 'utils/utiliesFunctions';
import { KeyHandlerCode } from 'data/constants';

import MenuItem from './MenuItem';
import './menu.css'
import { openVideoPlayer } from 'Services/redux/video/actions';
import voiceReader from 'utils/voiceReader';

import usePopup from 'hooks/usePopup';

let activeElement, visiblePage;

function MenuNew (props) {

	const menuGraphic = props.menu.graphic;

	let errCount = 0;

	for (let key in menuGraphic) {

		if (menuGraphic[key] == '#000000') {
			errCount++
		}

		if (errCount >= 4) {
			// console.error(menuGraphic, 'all Colors is black')
		}

	}

	const { appLogo, pages } = useSelector(({ app, entities }) => ({
		appLogo: menuGraphic.images.logo_open || app.graphic.appLogo,
		pages: entities.pages
	}), shallowEqual);

	const dispatch = useDispatch();
	const getWord = useLanguage();

	const defaultMenuLinks = [
		{
			title: getWord("exit"),
			icon: "",
			page_client_class: "exit",
			pageId: "exit"
		}
	];

	const [menuArray] = useState([...props.menu.links, ...defaultMenuLinks]);

	const menuRef = useRef();
	const menuBgRef = useRef();
	const menuListRef = useRef();

	useEffect(() => {
		const firstMenuItem = menuListRef.current.firstElementChild;
		setActiveMenuItem(firstMenuItem, false);
		setCurrentPage(firstMenuItem);

		// add mouseout event only if the platform support cursor
		if (window.settings.platformSettings.cursor) {
			menuRef.current.addEventListener("mouseout", (e) => {

				if (e.toElement === menuBgRef.current)
					dispatch(setKeyHandler());
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		// when the menu is active focus the active element 
		if (props.isActive) voiceReader.read(activeElement.innerText);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isActive]);

	const setActiveMenuItem = useCallback((elem, setFoucs = true) => {
		// if we have active element remove all active settings
		if (activeElement) {
			activeElement.classList.remove("active");
			activeElement.style.color = "inherit";
		}
		activeElement = elem;
		activeElement.classList.add("active");
		activeElement.style.color = menuGraphic.hover_color;
		setFoucs && voiceReader.read(activeElement.innerText);
	}, [menuGraphic.hover_color]);

	const setCurrentPage = useCallback((elem) => {
		visiblePage && visiblePage.classList.remove("selected");
		visiblePage = elem;
		visiblePage.classList.add("selected");
	}, [])

	const selectPage = event => {
		const elem = event.type === "click" ? event.currentTarget : activeElement;
		const id = +dataAttr(elem, "id");
		const page_type = dataAttr(elem, "type");

		// same page
		if (visiblePage === activeElement) {
			return dispatch(setKeyHandler());
		}

		switch (page_type) {
			case "exit":
				props.displayExitPopup(props);
				break;
			case "simple_video_player":
				const container = pages[id];

				dispatch(openVideoPlayer({
					video: container.firstVideo.videoId,
					content: container.content,
					backTo: KeyHandlerCode.MENU
				}));
				break;
			default:
				const newPage = props.menu.pages[id];
				const isHome = newPage.page_client_class === menuArray[0].page_client_class;
				const autoRemoveLoader = isHome || newPage.page_client_class === page_type;

				setCurrentPage(elem);

				startFadePageAnim(autoRemoveLoader, () => {
					if (isHome) {
						dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL));
						dispatch(setActiveCarouselManager("home"));
					}

					dispatch(setActivePage(isHome ? null : newPage));
				});
		}
	}

	const startFadePageAnim = (autoRemoval, cb) => {
		dispatch(setFadeScreen(true));
		setTimeout(() => {
			cb()
			autoRemoval && dispatch(setFadeScreen(false));
		}, 300);
	}

	const closeMenu = () => {
		// When we open the menu again the current page is highlighted
		setActiveMenuItem(visiblePage, false);
		dispatch(setKeyHandler());
	}

	useKeyHandler({
		keys: {
			right: closeMenu,
			down: () => {
				const newActiveElement = activeElement.nextElementSibling || menuListRef.current.firstElementChild;
				setActiveMenuItem(newActiveElement);
			},
			up: () => {
				const newActiveElement = activeElement.previousElementSibling || menuListRef.current.lastElementChild;
				setActiveMenuItem(newActiveElement);
			},
			enter: selectPage,
			back: props.displayExitPopup,
		},
		isActive: props.isActive
	});

	const onMouseOverMenuItems = useFunction(({ currentTarget }) => setActiveMenuItem(currentTarget))

	const onMouseClickMenuItems = useFunction(selectPage);

	const buildMenuClass = () => {
		let className = "menu-section";

		if (props.isActive)
			className += " active";

		if (!props.menu.menuOpen)
			className += " close_menu";

		return className;
	}

	const menuItemClass = index => {
		let className = "menu-item list";

		if (!props.menu.menuOpen && index === menuArray.length)
			className += " close";

		return className;
	}

	const memoMenu = useMemo(() => {

		return (

			menuArray.map((item, index) => (
				<li
					key={ item.pageId }
					className={ menuItemClass(index) }
					onClick={ onMouseClickMenuItems }
					onMouseEnter={ onMouseOverMenuItems }
					data-type={ item.page_client_class }
					data-id={ item.pageId }
					tabIndex={ -1 }
					aria-label={ item.title }
				>
					<MenuItem menuItem={ item } index={ index } isMenuActive={ props.isActive } />
				</li>
			))
		)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [menuArray, props.isActive])

	return (
		<div className={ buildMenuClass() }>
			<div className="menu-fullBG fullScreen" ref={ menuBgRef } />

			<div className="navigationMenu" ref={ menuRef }>
				<img className="logo-img-bigger" src={ appLogo } alt="big logo" />
			</div>

			<div className="menu-float-ui list">
				<div className="menu-item-logo menu_part">
					<img className="logo-img" src={ menuGraphic.images.logo_close } alt="small logo" />
				</div>

				<ul role="navigation" className="menuItemsList list" style={ { borderColor: menuGraphic.active_color, color: menuGraphic.text_color } } ref={ menuListRef }>
					{ memoMenu }
				</ul>

				<div className="powered-menu menu_part">
					{ menuGraphic.images.bottom_logo_open && <img className="fullImage" src={ menuGraphic.images.bottom_logo_open } alt="castify logo" /> }
					{ menuGraphic.images.bottom_logo_close && <img className="singleImage" src={ menuGraphic.images.bottom_logo_close } alt="castify logo" /> }
				</div>
			</div>
		</div>
	)
}

export default WithKeyHandler(React.memo(MenuNew), KeyHandlerCode.MENU);