import React from 'react';

function SideImage(props) {
	
	const imageLoded = event => {
		event.currentTarget.parentElement.style.opacity = "1"
	}

	return (
		<div className="side-image">
			<img src={props.image} width="100%" height="100%" onLoad={imageLoded} alt="sideImage" />
		</div>
	)
}
export default React.memo(SideImage);

