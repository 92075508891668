import Image from 'components/Widgets/images/Image';
import React from 'react';

function SocialItem({ socialItem }) {
	return (
		<div className="so-item">
			<img className="brand_image" src={socialItem.item_image} alt={`${socialItem.item_sub_type_id_name} logo`} />

			<p className="platform_name">{socialItem.item_sub_type_id_name}</p>

			<div className="item-qr">
				<Image
					src={socialItem.qr_img}
					width="140"
					height="140"
					alt={socialItem.item_sub_type_id_name}
				/>
			</div>

			<p className="item-link">{socialItem.short_link_url}</p>
		</div>
	)
}

export default React.memo(SocialItem);
