import React, { useEffect, useRef, useMemo } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import "./carousels.css"
import Thumbnail from './types/Thumbnail';
import { CarouselManagerTypes } from 'data/constants';
import VideoUI from './videoItemUI/VideoUI';
import MovieCarousel from './types/MovieCarousel';
import useFunction from 'hooks/useFunction';
import { createSelector } from 'reselect';



const makeSelectVideos = () =>
  createSelector(
    videos => videos,
    (_, videosList) => videosList,
    (allVideos, videosList) => {
      return videosList.reduce((total, current) => {
        return {
          ...total,
          [current]: allVideos[current]
        }
      }, {})
    }
  )

function Playlist (props) {

  const selectVideos = useMemo(makeSelectVideos, [])

  const { defaultThumbnail, videos, carousel } = useSelector(({ app, entities }) => {
    const _carousel = entities.carousels[props.carouselId];

    return {
      carousel: _carousel,
      defaultThumbnail: app.graphic.defaultThumbnail,
      videos: selectVideos(entities.videos, _carousel.videos)
    }
  }, shallowEqual);

  const carouselHolderRef = useRef();
  const sliderRef = useRef();

  const CustomTag = `${ props.carouselHeader }`;

  useEffect(() => {
    carouselHolderRef.current.setAttribute("data-height", carouselHolderRef.current.getBoundingClientRect().height.toFixed(2));

    if (carousel.videos.length)
      carouselHolderRef.current.setAttribute("data-width", sliderRef.current.firstChild.getBoundingClientRect().width.toFixed(2));
    // setCarousel(props.carousel);
    // activeVideoRef.current = carouselScrollRef.current.querySelector("div");
    // activeVideoRef.current.classList.add(activeClass)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderVideoItem = (videoId) => {
    const video = videos[videoId];

    const item = {
      defaultThumbnail,
      title: video.title,
      img: video.thumbnail_playlist || video.thumbnail,
      UI: <VideoUI
        is_live_streaming={video.is_live_streaming}
        videoDuration={video.videoDuration}
        parental_control={video.parental_control}
        currentTime={carousel.kind === "continue_watching" ? video.currentTime : 0}
      />
    }

    if (isCarouselManager(CarouselManagerTypes.PLAYER))
      return <Thumbnail {...item} />

    switch (carousel.type) {
      case "movie_carousel": return <MovieCarousel {...item} />
      case "basic_carousel":
      default: return <Thumbnail {...item} />
    }
  }

  const isCarouselManager = managerToTest => {
    return props.carouselManagerType === managerToTest;
  }

  const itemClicked = useFunction((event) => {
    props.onClick(event);
  })

  return (

    <div
      className="carousel_holder"
      tabIndex={-1}
      data-carousel-index={0}
      data-active-color={carousel.active_color}
      data-length={carousel.videos.length}
      data-carousel-id={carousel.entity_id}
      ref={carouselHolderRef}
    >

      <CustomTag
        className="carousel_title"
        style={{ color: props.carouselManagerType === CarouselManagerTypes.PLAYER ? "#fff" : carousel.carousel_title_color }}
      >
        {carousel.title}
      </CustomTag>

      <div className="carousel-slider-wrapper">

        <div
          className="slider"
          style={{ color: carousel.text_color }}
          data-carousel-id={carousel.entity_id}
          ref={sliderRef}
          data-kind={carousel.kind === "continue_watching" ? "continue_watching" : undefined}
        >

          {
            carousel.videos.map((videoId, index) => (

              <div
                key={videoId}
                className={`slider-item ${ !isCarouselManager(CarouselManagerTypes.PLAYER) ? carousel.type : "basic_carousel" }`}
                onMouseEnter={props.onMouseEnterVideo}
                data-index={index}
                data-video-id={videoId}
                onClick={itemClicked}
              >

                {renderVideoItem(videoId)}

              </div>

            ))}

        </div>

        {carousel.emptyText ? <p className="continueWatchingTitle">{carousel.emptyText}</p> : null}

      </div >

    </div >

  );
};

export default React.memo(Playlist);