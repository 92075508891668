import React, { useState } from 'react'
import useFunction from 'hooks/useFunction';
import useKeyHandler from 'hooks/useKeyHandler';

export default function ActionToggle (props) {

  const [isBtnActive, setIsBtnActive] = useState(props.option.startValue);

  const itemSelected = () => {
    setIsBtnActive(state => !state);
    props.onItemSelected(props.option.action);
  }

  useKeyHandler({
    keys: {
      enter: itemSelected
    },
    isActive: props.isActive,
  });

  const toggleStateOnClick = useFunction(itemSelected);
  return (
    <>
      <p className="item_title">{isBtnActive ? props.option.labelActive : props.option.label} </p>

      <div className="action_item_bg centerlize" onClick={toggleStateOnClick}>
        {isBtnActive ? <props.option.iconActive /> : <props.option.Icon />}
      </div>
    </>
  )
}