import PlayerInterface from 'Services/Player/playersCenter';
import Loader from 'components/Widgets/loaders/Loader';
import WithPopup from 'HOC/withPopup';
import React, { useState, useEffect, useRef } from 'react';
import { keyCodes } from 'data/keyCodes';
import "./article.css";
import CloseIcon from '@material-ui/icons/Close';

let timeout;

function Article(props) {
  const [hasLoaded, setHasLoaded] = useState(true);
  const [showExit] = useState(window.settings.platformSettings.cursor);
  const [displayBrandLogo, setDisplayBrandLogo] = useState(!!props.video.brand_logo)
  const parent = useRef();
  const iframe = useRef();

  useEffect(() => {
    const currentTime = Math.floor(PlayerInterface.action("getCurrentTime")) || 0
    iframe.current.src = props.video.action_url_settings.tracking_click.replace(/\$\{click_time\}/, currentTime);

    const iframeRoutine = (event) => {
      event.stopPropagation();
      clearTimeout(timeout);

      switch (event.keyCode) {
        case keyCodes.BACK: props.closeArticle(); break;
        default:
          iframe.current.focus();
          timeout = setTimeout(() => {
            parent.current.focus()
          }, 3000);
          break;
      }

    };

    const frame = iframe.current

    const onLoad = () => {
      setHasLoaded(false)
    }

    frame.addEventListener("load", onLoad);
    window.addEventListener("keydown", iframeRoutine, true);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("keydown", iframeRoutine, true);
      frame.removeEventListener("load", onLoad);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBrandLogoFailed = () => {
    setDisplayBrandLogo(false);
  }

  return (
    <div className="article_container" ref={parent} tabIndex={-1}>
      {displayBrandLogo && <div className="article_brand">
        <img src={props.video.brand_logo} alt="brand" onError={onBrandLogoFailed} />
      </div>}

      {showExit && <button className="articleButton centerlize" onClick={props.closeArticle}>
        <CloseIcon />
      </button>}

      {hasLoaded && <div className="articleLoader">
        <Loader />
      </div>}

      <iframe src="" title="article" ref={iframe}>
        This widget is not supported
      </iframe>
    </div>
  )
}

export default WithPopup(Article)

// aria-label={`${props.video.title} article`}