/**
* Init the app slice with data coming from the main json.
*
* @param {object} payload Object containing all data we need for the app slice
*/
export function setInitAppSlice (payload) {
	return {
		type: "app/init",
		payload
	};
}

/**
* Set current widget that listens to keydown event.
*
* @param {string} keyHandler The new widget
* @param {string} previousKeyHandler Override the previous keyhandler -usefull for back from menu or screen saver
*/
export function setKeyHandler (keyHandler, previousKeyHandler) {
	return {
		type: "apps/set-key-handler",
		keyHandler,
		previousKeyHandler
	};
}

/**
* Sets the visibilty of the fade screen.
*
* @param {boolean} payload The new state of the fade screen
*/
export function setFadeScreen (payload) {
	return {
		type: "apps/set-fadescreen",
		payload
	};
}

/**
* Displaying a page.
*
* @param {object} payload The page object
*/
export function setActivePage (payload) {
	return {
		type: "apps/set-active-page",
		payload
	};
}

/**
* Sets the language of the app - NOT INCLUDING texts coming from API.
*
* @param {object} payload The translate object
*/
export function setTranslation (payload) {
	return {
		type: "apps/set-language",
		payload
	};
}

/**
* Displaying popup.
*
* @param {string} popupType The kind of popup to show
* @param {object} [props] Additional settings to pass to the popup
*/
export function setPopupType (Component, props = {}) {
	return {
		type: "apps/set-popup",
		Component,
		props
	};
}


// temp soultion TODO

/**
* Activates specific carouesl manager (in case there are more than one carousel manager- e.x player). 
*
* @param {string} popupType The kind of popup to show
* @param {object} settings Additional settings to pass to the popup
*/
export function setActiveCarouselManager (payload) {
	return {
		type: "apps/set-active-carousel-key",
		payload
	};
}

/**
* Sets the splash screen image- this will also cause it display the splash screen.
* 
* @param {string} payload splashscreen image url
*/
export function setSplashScreen (payload) {
	return {
		type: "apps/set-splash-img",
		payload
	};
}