import React from 'react'

 function CurrentTime({progressTime, duration}) {
	return (
		<span className="current_end_time">
			{progressTime} / {duration}
		</span>
	)
}

export default React.memo(CurrentTime)
