import React from 'react'

export default function Browser_err() {
	return (
		<>
			<p>
				Our web Emulator is optimized for Chrome desktop browser. <br />
				Please use chrome.
			</p>
			<img id="chromeImg" src="https://cdn.castify.ai/files/general/chrome.png" alt="please use chrome" />

			<img id="castifyImg" src="https://cdn.castify.ai/files/app/castify_watermark.png" alt="castify" />
		</>
	)
}
