import React from 'react';
import "./pauseScreen.css";

function PauseScreen(props, pauseScreenRef) {
  return (
    <div className="pauseScreen" ref={pauseScreenRef}>
      <div className="pauseScreen-wrapper">
        <h2 className="pauseScreen-title">{props.title}</h2>
        <p className="pauseScreen-description cutLines">{props.description}</p>
      </div>
      <p className="pauseScreen-paused">Paused</p>
    </div>
  )
}

export default React.memo(React.forwardRef(PauseScreen))