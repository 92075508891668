import PlatformEvents from "utils/eventsBus";

export default class Emulator {
	constructor () {
		this.name = "emulator";
		this.IFAType = "ppid";
		this.IDFA = undefined;
		this.adsTracking = false;
		this.uuid = undefined;
		this.PlatformEvents = new PlatformEvents();
	}

	async initDevice (uaData) {

		try {

			if (Emulator.isNotSupportedDevice(uaData)) {

				let error = new Error("Device is not supported", { cause: "device" });
				console.warn(error.message);
				window.dispatchEvent(new ErrorEvent("error", { error }));

				throw error

			}

			const urlParams = new URLSearchParams(window.location.search);

			return {
				keys: {
					BACK: 27,
					STOP: 101,
					TOGGLE_PLAY: 32,
					REWIND: 102,
					FORWARD: 106,
					PLAY: 103,
					EXIT: 220,
					RED: 86
				},
				deepLinkData: {
					video: urlParams.get("video_id"),
					source: urlParams.get("source"),
					carousel: urlParams.get("carousel")
				},
				platformSettings: {
					animation: true,
					keyboard: false,
					cursor: true,
					encoding: "blob",
					playerCrossorigin: false,
					subtitles: true,
					hlsHelp: true
				}
			}
		}
		catch (err) {
			console.log("Cannot start emulator", err);
			throw err;
		}
	}

	static isNotSupportedDevice (uaData) {
		const unSupportedBrowsers = "explorer,edge,Microsoft Edge";
		const unSupportedDevices = "smartphone,mobile";

		const { uaFamily, deviceType } = uaData.ua;
		const browserRegex = new RegExp(uaFamily, "gi");
		const deviceRegex = new RegExp(deviceType, "gi");

		// return true if the emulators opens from phone or explore/edge
		return browserRegex.test(unSupportedBrowsers) || deviceRegex.test(unSupportedDevices);
	}

	getUUID = () => this.uuid;
	exitApp = () => window.close();
	getDeviceDetailed = () => navigator.platform;
	getAdsTracking = () => this.adsTracking;
	getIDFA = () => this.IDFA;
	getIFAType = () => this.IFAType;
}