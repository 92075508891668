import React, { useEffect } from 'react';
import ContentData from './ContentData';
import ImageTransition from 'components/Widgets/images/ImageTransition';
import { useSelector, shallowEqual } from "react-redux";
import { buildMetaData } from './metaData';
import voiceReader from 'utils/voiceReader';

function DetailedView (props) {

	const { defaultThumbnail } = useSelector(({ app }) => ({
		defaultThumbnail: app.graphic.defaultThumbnail
	}), shallowEqual);

	const metaContent = buildMetaData(props.selectedVideo);

	useEffect(() => {

		let timeout = setTimeout(() => {

			if (props.selectedVideo.description) {
				voiceReader.read(props.selectedVideo.description);
			}

		}, 2000);

		return () => {
			clearTimeout(timeout);
		}

	}, [props.selectedVideo.description]);

	return (
		<div className="detailed_view_container">
			<ImageTransition selectedVideo={ props.selectedVideo } defaultThumbnail={ defaultThumbnail } />

			<header className="app_info">
				<h1 className="home_title">{ props.selectedVideo.title }</h1>
				<ContentData contentData={ metaContent } />
				<p className="home_desc cutLines">{ props.selectedVideo.description }</p>
			</header>
		</div>
	)
}

export default DetailedView;
