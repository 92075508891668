import React from 'react'

function ParentControl ({ parental_control }) {
	return <img
		className="video_parent_control"
		src={parental_control.image}
		alt={parental_control.title}
		onError={(e) => { e.target.style.display = "none" }}
	/>
}

export default React.memo(ParentControl)