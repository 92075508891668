import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types';
import Image from './Image';

function ImageWloader(props) {
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		setIsLoaded(false);
	}, [props.src])

	const hideLoader = useCallback(() => {
		!isLoaded && setIsLoaded(true);
	},[isLoaded]);

	return (
		<>
			<Image
				src={props.src}
				className={"loader_image " + props.className}
				onLoad={hideLoader}
				onError={hideLoader}
				alt={props.alt}
				width={props.width}
				height={props.height}
				layout={props.layout}
			/>
			{!isLoaded && props.children}
		</>
	)
}

export default React.memo(ImageWloader)

ImageWloader.defaultProps = {
	width: "auto",
	height: "auto",
	className: "",
	default: "",
	loadingType: "eager"
}

ImageWloader.propTypes = {
	loaderType: PropTypes.string, // what loader should be shown while image is loading default- storeLoader (circle)
	className: PropTypes.string, // additonal class for add styles
	src: PropTypes.string.isRequired, // image src
	default: PropTypes.string, // show default image in case 'src' failed
	width: PropTypes.string, //the width of the image - default: auto
	height: PropTypes.string, // the height of the image - default: auto
	alt: PropTypes.string.isRequired, // alt text of the image
	hideImageOnFail: PropTypes.bool, //hide image if all src failed
	loadingType: PropTypes.string // load the image when in viewport or not
}