import React, { useEffect, useRef } from 'react';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useKeyHandler from 'hooks/useKeyHandler';
import { KeyHandlerCode } from 'data/constants';
import "./epgPopup.css";

function EpgPopup (props) {

    const titleRef = useRef(null);
    const descRef = useRef(null);

    useEffect(() => {

        if (props && props.popupProperties) {
            let { title, desc } = props.popupProperties;
            titleRef.current.innerHTML = title || "";
            descRef.current.innerHTML = desc || "";
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const hidePopup = () => {
        props.exitPopup(props.popupProperties.goToKeyhandler);
    }

    useKeyHandler({
        keys: { enter: hidePopup, back: hidePopup },
        isActive: props.isActive
    })

    return (
        <div className="epg_popup">
            <h2 ref={ titleRef }></h2>
            <p ref={ descRef }></p>
            <div className="okButton" onClick={ hidePopup }>
                <span>OK</span>
            </div>
        </div>
    )
}

export default WithKeyHandler(EpgPopup, KeyHandlerCode.EXIT_POP_UP);