import React from 'react';

function Sale() {
  return (
    <div className="sale">
      <span>SALE</span>
    </div>
  )
}

export default Sale;
