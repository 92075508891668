import React from 'react';

function ContentData(props) {
	return (
		<div className="content-data-container list">
			{props.contentData.map((item) => (
				<div className={"content-item-data " + item.className} key={item.className}>
					{item.icon && <img src={item.icon} alt={item.className} />}
					<p>{item.value}</p>
				</div>
			))}
		</div>
	)
}

export default React.memo(ContentData);