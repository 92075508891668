export const GET = (url, settings = {}) => {
  return new Promise((resolve, reject) => {
    if (!url) return reject("no url")
    const { defaultVal, type = "json" } = settings;

    const request = new XMLHttpRequest();
    request.responseType = type;

    request.onreadystatechange = () => {
      if (request.readyState === 4) {
        if (request.status === 200) { //success
          try {
            return resolve(request.response)
          }
          catch (error) {
            console.log(error);
            reject(`Data received, but there is an additional error, is it a valid File? url: ${url}`)
          }
        }

        if (request.status >= 400) { // for 400+ errors

          console.log(`Error code: ${request.status}, url: ${url}`)
          resolve(defaultVal)
        }
      }
    }
    request.open("GET", url, true);
    request.send();
  })
}


/*
url - request url 
settings - *all optionals*
 - type: response format. default: json,
 - defaultVal: in case of any error return this value
*/