/**
 * After transpiling the main json save all entites (carousels,pages, videos) in the redux store.
 * 
 * @param {object} entities entities objec with apges, videos etc...
 */
export function initEntities (entities) {
	return {
		type: "init",
		payload: entities
	};
}

/**
 * Add a video to a carouesl (only to continue watching / my list) 
 * 
 * @param {string} carouselType the type of the carousel to add a video to.
 * @param {string} videoId video ID.
 * @param {string|number} data Value to add. 
 */
export function addVideoToCarousel (carouselType, videoId, data) {
	// carousel type can be "continueWatching | myList" these are the only carousel that supported to get videos in runtime

	const carouselId = window.clientPlaylists[carouselType];
	if (!carouselId) return;

	const field = {};

	if (carouselType === "continueWatching")
		field.currentTime = data;
	else if (carouselType === "myList") {
		field.inList = true;
	}
	else {
		console.warn(`${carouselType} can't get new video`)
		return
	}

	return {
		type: "carousels/addVideo",
		payload: {
			carouselId,
			videoId,
			field
		}
	};
}

/**
 * Remove video from carousel (only continue watching / my list)
 * 
 * @param {string} carouselType The type of the carousel to remove a video from
 * @param {string} videoId Video Id
 */
export function removeVideoFromCarousel (carouselType, videoId) {

	const carouselId = window.clientPlaylists[carouselType];

	if (!carouselId) return;

	const field = {};

	if (carouselType === "continueWatching")
		field.currentTime = 0;
	else if (carouselType === "myList") {
		field.inList = false;
	} else {
		console.warn(`${carouselType} can't get new video`)
		return
	}

	return {
		type: "carousels/removeVideo",
		payload: {
			carouselId,
			videoId,
			field
		}
	};

}