import React, { useEffect, useRef } from 'react'
import withControlRow from 'HOC/withControlRow';
import useKeyHandler from 'hooks/useKeyHandler';
import useFunction from 'hooks/useFunction';
import { dataAttr } from 'utils/utiliesFunctions';

function SubOptions (props) {
	const optionsRef = useRef();
	const selectedItem = useRef();

	useEffect(() => {
		selectedItem.current = optionsRef.current.children[1];
		selectedItem.current.classList.add("selected");
	}, [])

	useEffect(() => {
		// on active set the foucs on the first child
		if (props.isActive) {
			props.updateElem(optionsRef.current.firstElementChild);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.isActive])

	const enter = (event) => {
		const elem = props.getElem();
		selectedItem.current.classList.remove("selected");
		selectedItem.current = elem;
		selectedItem.current.classList.add("selected");
		props.parentEnter(props.action, dataAttr(elem, "value"));
		// event.stopPropagation();
	}

	const handleClick = useFunction(enter);

	useKeyHandler({
		keys: {
			down: () => {
				const next = props.next();
				if (!next && props.downParent) props.downParent();
			},
			up: props.prev,
			enter
		},
		isActive: props.isActive,
		// capture: true
	});

	return (
		<ul className={ `options_items ${props.className || ""}` } ref={ optionsRef }>
			{ props.options.map((item, index) => (
				<li
					key={ item.label }
					data-index={ index }
					data-value={ item.value }
					onMouseOver={ props.onMouseOver }
					onClick={ handleClick }
				>
					<p className="captialize">{ item.label }</p>
				</li>
			)) }
		</ul>
	)
}

export default withControlRow(React.memo(SubOptions), "vertical")
