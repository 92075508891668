import React from 'react'

export default function DefaultLoader() {
	return (
		<div className="defaultLoaderContainer">
			<div className="double-bounce1" />
			<div className="double-bounce2" />
		</div>
	)
}
