import React from 'react';
// import voiceReader from 'utils/voiceReader';

function PageDescription ({ text, id, outline }, ref) {

	const createMarkup = () => {

		console.log('PageDescription createMarkup');

		// const voiceText = text.split('<')[0];
		// voiceReader.read(voiceText);

		return { __html: text };

	}

	return (
		<p
			id={ id }
			className={ "page-description" + (outline ? " outline" : "") }
			dangerouslySetInnerHTML={ createMarkup() }
			tabIndex={ -1 }
			ref={ ref }
		/>
	)
}

export default React.memo(React.forwardRef(PageDescription))