import React from 'react'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import useKeyHandler from 'hooks/useKeyHandler';
import WithKeyHandler from 'HOC/WithKeyHandler';
import { KeyHandlerCode } from 'data/constants';
import { useDispatch } from 'react-redux';
import { setKeyHandler } from 'Services/redux/app/actions';

function MenuSubPage(props) {
  const dispatch = useDispatch();

  useKeyHandler({
    keys: {
      back: props.closePage,
      enter: props.closePage,
      right: () => {
        dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL))
      }
    },
    isActive: props.isActive
  })

  return (
    <div className="hub_menu centerlize">
      <div className={"iconMenuHub centerlize" + (props.isActive ? " active" : "")} onClick={props.closePage}>
        <ArrowBackIcon />
      </div>
    </div>
  )
}

export default WithKeyHandler(MenuSubPage, KeyHandlerCode.HUB_MENU)