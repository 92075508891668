const getRect = (elem) => {
  const cr = elem.getBoundingClientRect();
  const rect = {
    left: cr.left,
    top: cr.top,
    width: cr.width,
    height: cr.height
  };
  rect.element = elem;
  rect.right = rect.left + rect.width;
  rect.bottom = rect.top + rect.height;
  rect.center = {
    x: rect.left + Math.floor(rect.width / 2),
    y: rect.top + Math.floor(rect.height / 2)
  };
  rect.center.left = rect.center.right = rect.center.x;
  rect.center.top = rect.center.bottom = rect.center.y;
  return rect;
}

export const navigateDown = () => {
  const current = getRect(document.querySelector(".btn.active"));
  const siblings = document.querySelectorAll(".btn")

  const newSiblings = [...siblings].map(getRect).filter(item => item.top >= current.bottom && getElementInRange(current, item))
  if (!newSiblings.length) return "goToGrid"

  setFocus(newSiblings[0], current)
}

export const navigateUp = () => {
  const current = getRect(document.querySelector(".btn.active"));
  const siblings = document.querySelectorAll(".btn")

  const newSiblings = [...siblings].map(getRect).filter(item => item.bottom <= current.top && getElementInRange(current, item))

  if (!newSiblings.length) {
    const finalTest = [...siblings].map(getRect).filter(item => item.bottom <= current.top && item.left === current.left)
    if (!finalTest.length) return
    
    return setFocus(finalTest[finalTest.length - 1], current)
  }

  setFocus(newSiblings[newSiblings.length - 1], current)
}


export const navigateRight = () => {
  const current = getRect(document.querySelector(".btn.active"));
  const siblings = document.querySelectorAll(".btn")
  const newSiblings = [...siblings].map(getRect).filter(item => item.top === current.top && item.left > current.center.x)

  if (!newSiblings.length) return

  setFocus(newSiblings[0], current)
}

export const navigateLeft = () => {
  const current = getRect(document.querySelector(".btn.active"));
  const siblings = document.querySelectorAll(".btn")
  const newSiblings = [...siblings].map(getRect).filter(item => item.top === current.top && item.left < current.left)

  if (!newSiblings.length) return

  setFocus(newSiblings[newSiblings.length - 1], current)
}

const setFocus = (newItemTofocus, current) => {
  newItemTofocus.element.focus();
  newItemTofocus.element.classList.add("active");
  current.element.classList.remove("active");
}

const getElementInRange = (target, destination) => {
  const center = target.center.x;
  const { left, right } = destination;

  return left < center && center < right
}