import React, { useEffect, useMemo, useRef } from 'react'
import CarouItemStory from './types/story/CarouItemStory';
import CarouItemGame from './types/game/CarouItemGame';

import { useDispatch, useSelector, shallowEqual } from "react-redux";
import useKeyHandler from 'hooks/useKeyHandler';
import { setKeyHandler, setPopupType } from "Services/redux/app/actions";

import { dataAttr } from 'utils/utiliesFunctions';

import { KeyHandlerCode } from 'data/constants';
import PropTypes from 'prop-types';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useFunction from 'hooks/useFunction';
import '../multiCategories.css'
import { createSelector } from 'reselect';
import voiceReader from 'utils/voiceReader';
import ExitPopUp from 'components/popups/exitPopup/ExitPopUp';

let offset = 0;

const makeSelectCategories = () =>
	createSelector(
		categories => categories,
		(_, categoriesList) => categoriesList,
		(categories, categoriesList) => {

			return categoriesList.map(categoryID => {
				const category = categories[categoryID]
				return {
					entity_id: category.entity_id,
					title: category.title,
					image: category.image,
					graphic: category.graphic,
				}
			}, {});

		}
	)

function MultiCategoriesCarousel (props) {

	const selectCategories = useMemo(makeSelectCategories, [])

	const dispatch = useDispatch();

	const { appLogo, categories } = useSelector(({ app, entities }) => ({
		appLogo: app.graphic.appLogo,
		categories: selectCategories(entities.categories, props.categories)
	}), shallowEqual);

	const carouselRef = useRef();
	const activeElem = useRef();
	const selectedElem = useRef();
	const clicksLeft = useRef(props.categories.length - 5);

	useEffect(() => {
		const firstItem = carouselRef.current.firstChild;
		carouselRef.current.style.borderColor = props.containerStyle.selectedColor;

		if (carouselRef.current.clientWidth < carouselRef.current.scrollWidth)
			carouselRef.current.classList.add("align_start");

		activeElem.current = firstItem;
		setSelectedElem(firstItem);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.categories.length, props.containerStyle.selectedColor]);

	useEffect(() => {
		if (props.isActive) {
			setActiveElem(activeElem.current);
			selectedElem.current.classList.remove("idle");
		} else {
			selectedElem.current.classList.add("idle");
			activeElem.current.classList.remove("active");
		}
	}, [props.isActive]);

	const setActiveElem = (elem) => {
		activeElem.current && activeElem.current.classList.remove("active");
		activeElem.current = elem;
		activeElem.current.classList.add("active");
		// activeElem.current.focus();
		let title = activeElem.current.querySelector(".cat-title");
		if (title) voiceReader.read(title.innerHTML)
	}

	const setSelectedElem = elem => {
		if (selectedElem.current) {
			selectedElem.current.style.color = "inherit";
			selectedElem.current.classList.remove("selected");
		}
		selectedElem.current = elem;
		selectedElem.current.classList.add("selected");
		selectedElem.current.style.color = props.containerStyle.activeColor;
	}

	const selectCategory = ({ type, currentTarget }) => {
		const element = type === "keydown" ? activeElem.current : currentTarget;

		props.setActiveCategory(+dataAttr(element, "index"));
		setSelectedElem(element);
		setActiveElem(element);
	}

	const slideMargin = (direction) => {
		const elemWidth = activeElem.current.clientWidth;
		offset += (direction * elemWidth);

		offset = offset > 0 ? 0 : offset;

		// margin.current = value * (graphic.rtl ? -1 : 1)
		carouselRef.current.style.transform = `translate3d(${offset}px,0 ,0)`
	}

	const renderCategories = (item) => {

		const commonProps = {
			item,
			onImageError: img => {
				img.target.src = appLogo;
			}
		}

		switch (props.containerStyle.type) {
			case "paralaxBox": return <CarouItemGame { ...commonProps } />
			case "story": return <CarouItemStory { ...commonProps } />
			default: console.log(`Unsupported categories type: ${props.containerStyle.type}`);
		}

	}

	const setFocousOnCategoris = () => dispatch(setKeyHandler(KeyHandlerCode.MULTI_CATEGORIES))

	useKeyHandler({
		keys: {
			left: () => {
				const _currentElem = activeElem.current;
				const prevElement = _currentElem.previousElementSibling;

				if (props.categories.length - 5 > clicksLeft.current) {
					clicksLeft.current++;
				}

				if (!prevElement) return dispatch(setKeyHandler(props.settings.horizntalEdge));

				slideMargin(1);

				setActiveElem(prevElement);
			},
			right: () => {
				const _currentElem = activeElem.current;
				const nextElement = _currentElem.nextElementSibling;

				if (!nextElement) return; // no element- reached end

				if (clicksLeft.current > 0) {
					clicksLeft.current--;
					slideMargin(-1);
				}
				// if (lastChild.getBoundingClientRect().right > window.innerWidth)
				//scroll if last element is not visible
				setActiveElem(nextElement);
			},
			down: () => dispatch(setKeyHandler(KeyHandlerCode.CAROUSEL)),
			enter: selectCategory,

			back: () => {
				// new code
				let existMenu = document.getElementsByClassName("menu-float-ui").length > 0;
				if (existMenu) dispatch(setKeyHandler(KeyHandlerCode.MENU));
				else dispatch(setPopupType(ExitPopUp));
			}
		},
		isActive: props.isActive,
		debounce: 200
	})

	const selectCategoryOnClick = useFunction(selectCategory);

	return (
		<div className={ "category_carousel " + props.containerStyle.type }>
			<ul className={ "category_carousel_inner list" } ref={ carouselRef } style={ { color: props.containerStyle.textColor } } onMouseEnter={ setFocousOnCategoris } tabIndex={ -1 } aria-label="amir">
				{ categories.map((item, index) => (
					<li key={ item.entity_id } className="cat-container" onClick={ selectCategoryOnClick } data-index={ index } tabIndex={ -1 } aria-label={ item.title }>
						{ renderCategories(item, index) }
					</li>
				)) }
			</ul>
		</div>
	)
}

MultiCategoriesCarousel.propTypes = {
	categories: PropTypes.array.isRequired, // the category carousel array
	setActiveCategory: PropTypes.func.isRequired // function to update active category
}

export default WithKeyHandler(React.memo(MultiCategoriesCarousel), KeyHandlerCode.MULTI_CATEGORIES)