import React, { useState, useEffect, useRef, useCallback } from 'react';
import './hub.css';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { setFadeScreen, setKeyHandler } from 'Services/redux/app/actions';
import { KeyHandlerCode } from 'data/constants';
import HubSubPages from './HubSubPages';
import Grid from 'components/videosGrid/Grid';
import HubItem from './hubItem/HubItem';
import PageHeader from 'components/pagesWidgets/PageHeader';

export default function Hub(props) {
  const dispatch = useDispatch();
  const { graphic, activePage } = useSelector(({ app }) => ({
    graphic: app.graphic,
    activePage: app.activePage
  }), shallowEqual);

  const [activeContainer, setActiveContainer] = useState(null);
  const bg = useRef();

  useEffect(() => {
    dispatch(setKeyHandler(KeyHandlerCode.GRID));
    bg.current.style.backgroundImage = `url(${graphic.backgroundImg})`;

    setTimeout(() => {
      dispatch(setFadeScreen(false));
    }, 400);
  }, [graphic.backgroundImg, dispatch]);

  const openApp = activeIndex => {
    setActiveContainer(props.container.content[activeIndex])
  }

  const renderItem = useCallback((item) => <HubItem item={item} />, [])

  return (
    <div ref={bg}>
      {props.kind === "page" && <PageHeader appName={graphic.appName} title={activePage.page_title || activePage.menu_title} color={activePage.graphic.title_color} />}

      <div className="hub_grid hubPageContainer">
        <Grid content={props.container.items} renderItem={renderItem} class="hub_item" enter={openApp} />
      </div>

      {activeContainer && <HubSubPages setActiveContainer={setActiveContainer} activeContainer={activeContainer} />}

    </div>
  )
}