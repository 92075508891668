import React from 'react';
import PropTypes from 'prop-types';
import "./thumbnail.css";
import Image from 'components/Widgets/images/Image';

function Thumbnail ({ title, img, defaultThumbnail, UI }) {

	return (
		<>
			<div className="thumbnail-container">
				<Image layout="cover" className="thumbnail-img" src={ img } defaultImage={ defaultThumbnail } />
				{ UI }
			</div>

			<div className="video_info">
				<span className="video_title cutLines">{ title }</span>
			</div>
		</>
	)
}

Thumbnail.propTypes = {
	title: PropTypes.string.isRequired, // the video title 
	img: PropTypes.string.isRequired, // the video img 
	defaultThumbnail: PropTypes.string, // fallback image if video thumbnail failes
	style: PropTypes.string // video style
}

export default React.memo(Thumbnail);