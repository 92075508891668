/**
 * Check if media entity is allowed by check it own restriction rule
 * 
 * @param {object} mediaEntity Can be video / carouesl / category to check.
 * @returns {boolean} Whether or not the entity is restricted and should be removed.
 */
export const isRestrictedItem = (mediaEntity) => {
	const isoCode = window.settings.appSettings.isoCode;

	if (mediaEntity && mediaEntity.restrictions && mediaEntity.restrictions.geos) {
		const { whitelist_countries, blacklist_countries } = mediaEntity.restrictions.geos;
		// if the video has white list, the country should be in that list in order to pass the restriction
		if (whitelist_countries)
			return !whitelist_countries.includes(isoCode);

		// if the video has black list, the country should *not* be in that list in order to pass the restriction
		if (blacklist_countries)
			return blacklist_countries.includes(isoCode);
	}

	return false;
}