// platforms
import Emulator from "./emulator";
import Samsung from "./samsung";
import LG from "./lg";
import Zeasn from "./zeasn";
import Vizio from "./vizio";
import Vidaa from "./vidaa";

import { setKeyBinding } from "../../data/keyCodes";
import appSettings from "data/appSettings.json";
import Analytics from "Services/analytics/globalAnaltyics";

/**
 * Moudle to interact with the platform properties.<br>
 * Since we support varios platforms and every platform has it's own API
 * we use this moudle.
 * 
 * @class
 */
class Devices {
  constructor () {
    /**
     * Reference to platform module.
     */
    this.platformInstance = Devices._getInstance();
  }

  /**
   * Identify the deivce using useragent.
   * 
   * @returns Device class Instance
   */
  static _getInstance () {
    const userAgent = navigator.userAgent;

    if ((/webos|web0s/i).test(userAgent)) // LG
      return new LG();
    else if ((/tizen/i).test(userAgent)) // samsung
      return new Samsung();
    else if ((/nettv|sraf|tcl|iServer|WhaleTV/i).test(userAgent)) // zeasn
      return new Zeasn();
    else if ((/vizio|smartcast|conjure/i).test(userAgent)) // vizio
      return new Vizio();
    else if ((/"vidaa|hisense/i).test(userAgent)) // vidaa
      return new Vidaa();
    else
      return new Emulator();
  }

  /**
   * initilaize the platform module, get the platfrom specific keys and bind them.
   * Set all window object fields.
   * 
   * @param {string} uaData User agent data
   * @param {string} isoCode User country in ISO code (IL, US, FR)
   */
  async init (uaData, isoCode) {
    const platformInfo = await this.platformInstance.initDevice(uaData);
    window.settings.platform = this.platformInstance.name;
    window.settings.platformSettings = { ...platformInfo.platformSettings };
    window.settings.appSettings = { ...appSettings, isoCode, approved_sensitive_content: false };
    window.settings.deepLinkData = { ...platformInfo.deepLinkData };

    setKeyBinding(platformInfo.keys);
  }

  /**
   * Add listener to a platform event, only to platforms that implementing the PlatformEvents module.
   * 
   * @param {string} eventName The event name
   * @param {Function} func The callback to run
   */
  registerPlatformEvent (eventName, func) {
    // not all platforms will have this ability to first we need to make sure we have that function.
    if (this.platformInstance.PlatformEvents)
      this.platformInstance.PlatformEvents.add(eventName, func);
  }

  /**
   * Remove platform event listener.
   * 
   * @param {string} eventName The event name to remove
   * @param {Function} func The callback to remove
   */
  removePlatformEvent (eventName, func) {
    if (this.platformInstance.PlatformEvents)
      this.platformInstance.PlatformEvents.remove(eventName, func);
  }

  /**
  * get device's system data.
  * 
  * @param {string} value System value like: IFA, UUID.
  */
  getDeviceFieldData (value) {
    try {
      return this.platformInstance[value]();
    } catch (err) {

      let error = new Error(`GetDeviceFieldData: ${err.message}`);
      console.warn(error.message);
      window.dispatchEvent(new ErrorEvent("error", { error }));

      return ""
    }
  }

  /**
   * Close app, will also send exit event
   */
  exitApp () {
    Analytics.sendEvent("exitApp");
    this.platformInstance.exitApp();
  }

  /**
   * when document visibility changed.
   */
  onDocumentHide () {
    if (this.platformInstance.onDocumentHide)
      this.platformInstance.onDocumentHide();
  }
}

export default new Devices();