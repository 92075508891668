import React from 'react'
import {formatTimeString} from 'utils/utiliesFunctions'
import LiveLabel from 'components/videoPlayer/components/UI/LiveLabel'

function VideoDuration({ videoDuration, is_live_streaming }) {

	return (
		<div className={"video_duration" + (!videoDuration && !is_live_streaming ? " none" : "")}>
			{is_live_streaming
				? <LiveLabel />
				: formatTimeString(videoDuration)
			}
		</div>
	)
}

export default React.memo(VideoDuration)