import React, { useEffect } from 'react';
import WithKeyHandler from 'HOC/WithKeyHandler';
import useKeyHandler from 'hooks/useKeyHandler';
import useLanguage from 'hooks/useLanguage';
import { KeyHandlerCode } from 'data/constants';
import "./videoError.css";

function VideoErrorPopup(props) {
	const getWord = useLanguage();

	useEffect(() => {

		const autoRemovalPopup = setTimeout(hidePopup, 5000);

		return () => {
			clearTimeout(autoRemovalPopup);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const hidePopup = () => {
		props.exitPopup(props.popupProperties.goToKeyhandler);
	}

	useKeyHandler({
		keys: { enter: hidePopup, back: hidePopup },
		isActive: props.isActive
	})

	return (
		<div className="videoError_popup">
			<h2>{props.popupProperties.type === "no_connection" ? "Please Check your internet connection" : getWord("videoFailed")}</h2>
			<div className="okButton" onClick={hidePopup}>
				<span>OK</span>
			</div>
		</div>
	)
}

export default WithKeyHandler(VideoErrorPopup, KeyHandlerCode.EXIT_POP_UP);