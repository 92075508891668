import React from "react";
import { connect } from "react-redux";
import { setKeyHandler, setTranslation, setPopupType, setInitAppSlice, setSplashScreen } from "Services/redux/app/actions";
import { initEntities } from "Services/redux/entities/actions";
import { setOpenVideoPlayer } from "Services/redux/video/actions";

// components
import Menu from "../Menu/Menu";
import PageMaster from "../../pages/PageMaster";
import ScreenSaver from "../screenSaver/ScreenSaver";
import VideoPlayer from "../videoPlayer/VideoPlayer.js";
import SplashScreen from "../splashScreen/SplashScreen.js";
import ErrorComp from "../Error/Error";
import HomePage from "../../pages/home/HomePage";
import GlobalPopups from "../popups/GlobalPopups";
import ExitPopUp from "components/popups/exitPopup/ExitPopUp";

// services
import { keyCodes } from "data/keyCodes";
import getData from "utils/getData";
import DeviceCenter from "Services/platforms/deviceCenter";
import { KeyHandlerCode } from "data/constants";
import getGlobalGraphicObj from "utils/objectCreators/globalGraphic";
import ChannelSettings from "utils/channelSettings/ChannelSettings";

class AppManager extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			menu: null,
			displaySplashScreen: true,
			screenSaverOn: false,
			errorType: "",
			data: null,

		};

		this.ads = {};
		this.timeToShowScreensaver = 0;
		this.homePageMounted = false;
		this.splashScreenImageHasLoaded = false;
	}

	async componentDidMount () {

		try {

			// get data and send callback function to display the splash screen when possible
			const data = await getData(this.props.setSplashScreen);
			this.onGetJsonSuccess(data);

		} catch (error) {
			console.error(error);
			this.displayError(error.cause);
		}

	};

	onGetJsonSuccess = ({ json: transpiledJson, translation }) => {

		const { graphic, entites, appInfo, menu, ads } = transpiledJson;

		const hasMenu = transpiledJson.menu !== undefined;
		this.timeToShowScreensaver = graphic.screen_saver_time_on * 1000;
		this.ads = ads;

		// redux states
		this.props.initEntities(entites);
		this.props.setInitAppSlice({
			graphic: getGlobalGraphicObj(graphic, { hasMenu }),
			translation
		});

		this.setState({ data: graphic })
		this.buildDocumentData(graphic, hasMenu, appInfo);

		this.addEvents();
		this.runScreenSaverCounter();

		this.setState({ menu });

		this.playVideoOnStart();

		const [brighData] = ChannelSettings.getPlugins("brighData");

		if (brighData) {

			brighData.init({ app_name: graphic.appName, app_logo: graphic.appLogo })
				.then(() => {

					if (brighData.tag.status == "true" && brighData.status && !brighData.status.consent) {
						brighData.enable();
					}

				})
				.catch(err => {
					console.error(err);
				});

		}

	}

	buildDocumentData = (graphic, hasMenu, appInfo) => {
		const { appName, rtl, text_outline } = graphic;

		const classes = [window.settings.platform, hasMenu ? "v5" : "v4", text_outline ? "outline1" : "", `brand_${ appInfo.parentHash }`].filter(item => !!item);

		document.title = appName || "CastifyTV";
		document.body.classList.add(...classes);
		document.documentElement.dir = rtl ? "rtl" : "ltr";

		const metaTag = Object.assign(document.createElement("meta"), {
			name: "version",
			content: window.settings.appSettings.version
		});

		document.head.insertBefore(metaTag, document.head.firstChild);
	}

	addEvents = () => {

		window.addEventListener("keydown", this.onGlobalKeyDown);
		window.addEventListener("mousemove", this.stopScreenSaver);

		// listen to toggle caption event (platform event)
		DeviceCenter.registerPlatformEvent("toggleCaptions", (ccState) => {
			window.settings.platformSettings.subtitles = ccState;
		});

		DeviceCenter.registerPlatformEvent("contentChange", (videoId) => {
			this.props.setOpenVideoPlayer({
				video: videoId,
				carouselId: "",
				content: [],
				backTo: KeyHandlerCode.CAROUSEL
			});
		});
	}

	onGlobalKeyDown = event => {
		// every time we click on a key reset the screensaver countdown
		this.stopScreenSaver();

		switch (event.keyCode) {
			case keyCodes.EXIT:

				event.preventDefault();
				event.stopPropagation();
				this.displayExitPopup()
				this.stopScreenSaver(false);
				break;
			case keyCodes.BACK:
				event.preventDefault();

				break;
			default: break;
		}
	}


	displayExitPopup = () => {

		clearTimeout(this.screenSaverTimeOut);
		this.stopScreenSaver(false);
		this.props.setPopupType(ExitPopUp, {
			onClose: this.runScreenSaverCounter,
		});

	}

	displayError = (type = "maintenance") => {
		this.stopScreenSaver(false); // stop screen saver and cancel the next counting

		this.setState({
			errorType: type
		});
	}

	hideError = () => {
		this.runScreenSaverCounter();
		this.setState({
			errorType: ""
		})
	}

	/**
	 * Play video on app load, get the video ID from window.settings.deepLinkData object.<br>
	 */
	playVideoOnStart = () => {
		const { video, carousel } = window.settings.deepLinkData;
		if (!video) return;

		// setTimeout(() => {

		console.log("playVideoOnStart", video, carousel);

		this.props.setOpenVideoPlayer({
			video,
			carouselId: carousel,
			content: carousel || [],
			backTo: KeyHandlerCode.CAROUSEL
		});

		// }, 1000);
	}

	/**
	 * Stop the screen saver timeout, in case the screen saver is on- remove it. <br>
	 * After canceling the timeout call the timeout again.
	 * 
	 * @param {boolean=true} shouldCallScreenSaver Whether or not call the the screen saver timeout again 
	 */
	stopScreenSaver = (shouldCallScreenSaver = true) => {
		// if this value is falsy (NaN, 0) don't try to reset the screen saver
		if (!this.timeToShowScreensaver) return;

		if (this.state.screenSaverOn) {
			this.props.setKeyHandler();
			this.setState({
				screenSaverOn: false
			});
		}

		clearTimeout(this.screenSaverTimeOut);
		shouldCallScreenSaver && this.runScreenSaverCounter();
	}

	/**
	 * Run screen saver timeout, after timeout display the screenSaver component.
	 */
	runScreenSaverCounter = () => {

		// just make sure that we dont attempt to show the screen saver in case we shouldn't
		if (!this.timeToShowScreensaver) return;

		// Cancel timeout So we won't call the screenSaver twice. 
		clearTimeout(this.screenSaverTimeOut);
		// if (this.state.isOpen) return;

		this.screenSaverTimeOut = setTimeout(() => {
			if (document.getElementsByClassName("popup_container").length) return console.log("popup is open");
			// display the screenSaver component
			this.setState({ screenSaverOn: true });
		}, this.timeToShowScreensaver);

	}

	onVideoPlayerMounted = () => {

		// disable all events we regsiter here, the player will handle key events
		window.removeEventListener("mousemove", this.stopScreenSaver);
		window.removeEventListener("keydown", this.onGlobalKeyDown);

		// stop the screen saver and disable auto counting
		this.stopScreenSaver(false);

	}

	onVideoPlayerClosed = () => {

		this.runScreenSaverCounter(); //  start screen saver timeout
		window.addEventListener("keydown", this.onGlobalKeyDown);
		window.addEventListener("mousemove", this.stopScreenSaver);

	}

	onHomePageMounted = () => {

		if (this.splashScreenImageHasLoaded) this.hideSplashImage();
		else this.homePageMounted = true;

	}

	onSplashScreenLoaded = () => {

		if (this.homePageMounted) this.hideSplashImage();
		else this.splashScreenImageHasLoaded = true;

	}

	//display splash screen for at least 2.5 seconds
	hideSplashImage = () => {

		setTimeout(() => {

			// if have error in the app cancel everything
			if (this.state.errorType) return;

			// this.playVideoOnStart();
			this.setState({ displaySplashScreen: false });

		}, 2500);

	}

	render () {

		return (
			<main className="main">
				{this.state.menu && <Menu menu={this.state.menu} displayExitPopup={this.displayExitPopup} />}
				{this.state.displaySplashScreen && <SplashScreen onSplashScreenLoaded={this.onSplashScreenLoaded} onAppError={this.state.errorType} />}
				<HomePage onHomePageMounted={this.onHomePageMounted} />
				<PageMaster />
				{this.props.displayVideoPlayer && <VideoPlayer watermark_image={this.state.data.watermark_image} onMounted={this.onVideoPlayerMounted} onClose={this.onVideoPlayerClosed} ads={this.ads} />}
				{this.state.screenSaverOn && <ScreenSaver data={this.state.data} />}
				{this.state.errorType && <ErrorComp err_type={this.state.errorType} />}
				<GlobalPopups />
			</main>
		);
	}
}

function mapStateToProps ({ app, video }) {
	return {
		displayVideoPlayer: video.displayVideoPlayer,
		// graphic: app.graphic,
		// entities,
		// activeCarouselManager: app.activeCarouselManager,
		// keyHandler: app.keyHandler, // De this to see what is the active keyhandler -remove on production
		// previousKeyHandler: app.previousKeyHandler, // add this to see what is the  previous active keyhandler -remove on production
		// keyHandlerBeforePlayer: app.keyHandlerBeforePlayer
	};
}

export default connect(mapStateToProps, {
	setKeyHandler,
	setTranslation,
	setPopupType,
	initEntities,
	setOpenVideoPlayer,
	setInitAppSlice,
	setSplashScreen
})(AppManager);