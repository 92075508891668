import { keyCodes } from "data/keyCodes";
import { addKeyHandler } from "utils/utiliesFunctions";
import parser from "utils/parser";

/**
 * Request and get ads from google IMA.<br>
 * Please note: this won't work if adBlocker is active.
 * 
 * @class
 */
export default class IMA {
	constructor(data) {
		if (!IMA.checkAvailability()) throw new Error("IMA couldn't be loaded");

		/**
		 * Castify player ref.
		 */
		this.player = data.player; // castifyPlayer reference

		/**
		 * ads call back to run when ad started, finished or failed.
		 */
		this.adsCallbacks = data.callbacks; // events to run when ads are failed / ended

		/**
		 * Current ad break object, contains data about the ad break.
		 * 
		 * @type {adBreak}
		 */
		this.currentAdBreak = null;

		/**
		 * Counter to the remaining time for an ad.
		 */
		this.countdownTimer = null;
		// ui components

		/**
		 * Element to display ad countdown.
		 * 
		 * @type {HTMLDivElement}
		 */
		this.timerUI = null;

		/**
		 * Ad container to display the ads on.
		 * 
		 * @type {HTMLDivElement}
		 */
		this.adContainer = null;

		// build the UI
		this.buildDOM();
		// ad components      
		this.adsManager = null;
		this.adsRenderingSettings = null;

		this.adDisplayContainer = new window.google.ima.AdDisplayContainer(this.adContainer);
		this.adsLoader = new window.google.ima.AdsLoader(this.adDisplayContainer); // object request ads form ad servers
		this.adsLoader.addEventListener(window.google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, this.onAdsManagerLoaded, false, this);
		this.adsLoader.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError, false, this);
	}


	static checkAvailability () {

		return window.google !== undefined;
	}

	cleanup () {
		if (this.adsLoader)
			this.adsLoader.destroy();

		if (this.adsManager)
			this.adsManager.destroy();
	}

	buildDOM () {
		this.adContainer = document.createElement("div");
		this.timerUI = document.createElement("div");

		this.adContainer.id = "adContainer";
		this.timerUI.id = "adTimer";

		this.adContainer.appendChild(this.timerUI);
		this.player.wrapperRef.appendChild(this.adContainer);
	}

	regeisterKeys () {
		const keyDownEvent = ({ keyCode }) => {
			switch (keyCode) {
				case keyCodes.PAUSE: this.adsManager.pause(); break;
				case keyCodes.PLAY: this.adsManager.resume(); break;
				default: break;
			}
		}

		addKeyHandler(keyDownEvent);
	}

	onAdsManagerLoaded (adsManagerLoadedEvent) {

		console.log("ads manager loaded", adsManagerLoadedEvent);

		this.adsRenderingSettings = new window.google.ima.AdsRenderingSettings();
		this.adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true;

		this.adsManager = adsManagerLoadedEvent.getAdsManager(this.player.getMediaRef(), this.adsRenderingSettings);

		const adEvent = [
			["CONTENT_PAUSE_REQUESTED", this.onContentPauseRequested],
			["CONTENT_RESUME_REQUESTED", this.onContentResumeRequested],
			["STARTED", this.onStarted],
			["IMPRESSION", this.adsCallbacks.onImpression],
			["COMPLETE", this.onAdEnded],
			["AD_BUFFERING", this.player.displayLoader],
			["AD_CAN_PLAY", this.player.hideLoader],
			["FIRST_QUARTILE", this.adsCallbacks.onFirstQuartile],
			["THIRD_QUARTILE", this.adsCallbacks.onThirdQuartile],
			["AD_PROGRESS", this.addProgress],
		]

		for (const [type, action] of adEvent)
			this.adsManager.addEventListener(window.google.ima.AdEvent.Type[type], action, false);

		// handle errors
		this.adsManager.addEventListener(window.google.ima.AdErrorEvent.Type.AD_ERROR, this.onAdError, false, this);

		try {

			const mediaRef = this.player.getMediaRef();
			const { clientWidth: width, clientHeight: height } = mediaRef;
			this.adsManager.init(width, height, window.google.ima.ViewMode.NORMAL);
			this.adsManager.start();

		} catch (adError) {
			console.log("Ad manager could not be started");
			this.onAdError();
		}
	}

	addProgress (event) {

		if (document.querySelector(".showControls")) document.querySelector(".showControls").classList.remove("showControls");

		let addCountg = document.getElementById("ads-counting");

		let data = event.getAdData();

		if (!data) {
			if (addCountg) addCountg.innerHTML = "";
			return;
		}

		let { adBreakDuration, adPosition, currentTime, duration, totalAds, } = data;

		if (addCountg) addCountg.innerHTML = `${ adPosition } of ${ totalAds }`;

	}

	requestAd (adBreak, vast) {

		// vast = "https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/vmap_ad_samples&sz=640x480&cust_params=sample_ar%3Dpremidpostpod&ciu_szs=300x250&gdfp_req=1&ad_rule=1&output=vmap&unviewed_position_start=1&env=vp&impl=s&cmsid=496&vid=short_onecue&correlator="

		this.currentAdBreak = adBreak;
		this.adDisplayContainer.initialize();

		const macros = {
			"[VIDEO_TITLE]": this.player.currentVideo.title,
			"[VIDEO_CONTENT_ID]": this.player.currentVideo.id
		}

		const adsURL = parser.parse(vast, macros); // get ad url after macro changes
		const mediaElem = this.player.getMediaRef();

		const adsRequest = new window.google.ima.AdsRequest();

		adsRequest.adTagUrl = adsURL;
		adsRequest.linearAdSlotWidth = mediaElem.offsetWidth;
		adsRequest.linearAdSlotHeight = mediaElem.offsetHeight;
		adsRequest.nonLinearAdSlotWidth = mediaElem.clientWidth;
		adsRequest.nonLinearAdSlotHeight = mediaElem.clientHeight / 3;
		adsRequest.vastLoadTimeout = adBreak.vastload;
		adsRequest.setContinuousPlayback = true;

		this.adsLoader.requestAds(adsRequest);

	}

	onContentPauseRequested = () => {
		this.adsCallbacks.onAdBreakStarts();
	}

	onStarted = () => {

		if (document.querySelector(".showControls")) document.querySelector(".showControls").classList.remove("showControls");

		this.adsCallbacks.onAdStarted();

		this.timerUI.innerText = Math.floor(this.adsManager.getRemainingTime());
		this.timerUI.style.display = "block";

		this.countdownTimer = setInterval(() => {
			const time = this.adsManager.getRemainingTime()
			this.timerUI.innerText = Math.floor(time < 0 ? 0 : time);
		}, 1000);
	}

	onContentResumeRequested = () => {
		this.adsCallbacks.onAdBreakFinished(this.currentAdBreak);
	}

	onAdEnded = () => {
		this.stopTimer();
		this.player.displayLoader();
		this.adsCallbacks.onAdComplete(this.currentAdBreak);
	}

	stopTimer = () => {
		this.timerUI.style.display = "none";
		clearInterval(this.countdownTimer);
	}

	onAdError (err) {

		const error = err.getError();

		console.log("Ad error", error);

		this.stopTimer();
		this.adsCallbacks.onAdError(this.currentAdBreak, error); //player ref event

		this.adsManager && this.adsManager.destroy();

	}
}