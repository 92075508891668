import React, { useEffect } from 'react';
import { useSelector, shallowEqual } from "react-redux";
import DefaultLoader from './DefaultLoader';

export default function Loader () {

	const { loaderImage } = useSelector(({ app }) => ({
		loaderImage: app.graphic.loader_image
	}), shallowEqual);

	const [loader, setLoader] = React.useState(null);

	useEffect(() => {

		if (loaderImage) {
			const img = new Image();
			img.src = loaderImage;
			img.onload = () => {
				setLoader(<img src={ loaderImage } />);
			}
			img.onerror = () => {
				setLoader(<DefaultLoader />);
			}
		} else {
			setLoader(<DefaultLoader />);
		}

	}, [loaderImage])

	return loader;

}