
/**
 * This service will read text out loud using assistive technology.<br>
 * We will mostly set the focus on the element itself and then using the aria-* atrributes the assistive technology
 * will read the text.<br>
 * But sometimes we want to overwrite the text or maybe we can focus the element (since TV will flick the page to that element)<br>
 *
 * note: focus({preventScroll: true)} not working on TV.
 * 
 */
// const synth = window.speechSynthesis;

class VoiceReader {

    constructor () {

        this.element = Object.assign(document.createElement("div"), { id: "voiceReader", tabIndex: -1 });

        this.vizioVoiceReader = false;

        if (window.VIZIO) {

            document.addEventListener("VIZIO_TTS_ENABLED", function () {
                this.vizioVoiceReader = true;
            });

            document.addEventListener("VIZIO_TTS_DISABLED", function () {
                this.vizioVoiceReader = false;
            });

        }

        document.body.appendChild(this.element);

    }

    read (text, cb) {

        this.element.blur();
        clearTimeout(this.setSpeekTimeout);
        if (window.VIZIO) window.VIZIO.Chromevox.cancel();

        // synth.cancel()
        // const SelectedVoice = synth.getVoices()[0];

        this.element.innerText = "";

        if (text) this.element.innerText = text;

        var regex = /(<([^>]+)>)/ig;

        this.element.innerHTML = this.element.innerHTML.replace(regex, " ");

        // if (window.webOS && window.webOS.platform && window.webOS.platform.tv) {

        //     webOS.service.request("luna://com.webos.service.tts/", {
        //         method: "stop"
        //     });

        //     webOS.service.request("luna://com.webos.service.tts/", {
        //         method: "speak",
        //         parameters: {
        //             text: this.element.innerText,
        //             clear: true
        //         }
        //     });

        // }

        this.setSpeekTimeout = setTimeout(() => {

            // const utterThis = new SpeechSynthesisUtterance(text);
            // utterThis.voice = SelectedVoice;
            // synth.speak(utterThis);

            if (!this.element.innerHTML) return;

            this.element.blur();
            this.element.focus();
            if (cb) cb();

            // if (window.VIZIO) {
            //     if (this.vizioVoiceReader) window.VIZIO.Chromevox.play(this.element.innerHTML);
            // } else {
            //     this.element.blur();
            //     this.element.focus();
            // }

        }, 500);

    }

}

export default new VoiceReader();