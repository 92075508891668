import { KeyHandlerCode } from 'data/constants';

const initialState = {
  /**
   * Active focusable widget
   */
  keyHandler: KeyHandlerCode.CAROUSEL,

  /**
   * Previous focusable widget
   */
  previousKeyHandler: KeyHandlerCode.CAROUSEL,

  /**
   * NO-OP
   */
  homeScreen: 1,

  /**
   * Global graphics properties
   */
  graphic: {},

  /**
   * Display Fade screen
   */
  displayFadeScreen: false,

  /**
   * Active page
   */
  activePage: null,

  /**
   * Language object
   */
  translation: {},

  /**
   * popup to display
   */
  popupData: null,

  /**
   * focused caroueslManager
   */
  activeCarouselManager: "home",

  /**
   * previous focused caroueslManager
   */
  prevActiveCarouselManager: "",

  /**
   * splash image url
   */
  splashScreenUrl: ""
}

/**
 * App reducer to interact with global app states like grpahic or tranlations.
 * 
 * @param {object} state InitialState
 * @param {object} action Action object to update the state  
 */
export default function appReducer (state = initialState, action) {
  switch (action.type) {
    case "app/init":
      return {
        ...state,
        graphic: action.payload.graphic,
        translation: action.payload.translation,
        homeScreen: +action.payload.graphic.homeScreen
      }

    case "apps/set-splash-img": return { ...state, splashScreenUrl: action.payload }
    case "apps/set-language": return { ...state, translation: action.payload }
    case "apps/set-fadescreen": return { ...state, displayFadeScreen: action.payload }
    case "apps/set-active-page": return { ...state, activePage: action.payload }
    case "apps/set-popup": return { ...state, popupData: action.Component === null ? null : { Component: action.Component, props: action.props } }
    case "apps/set-active-carousel-key":
      return {
        ...state,
        activeCarouselManager: action.payload ? action.payload : state.prevActiveCarouselManager,
        prevActiveCarouselManager: action.payload ? state.activeCarouselManager : state.prevActiveCarouselManager
      }
    case "apps/set-key-handler":
      let previousKeyHandler;

      const { keyHandler: currentActive, previousKeyHandler: currentPrev } = state;
      // list of keyHandler codes which we need to save the previous keyHandler, when opening menu for e.x we need to know where to return, or when we exit video player 
      const prevHandlers = [KeyHandlerCode.MENU, KeyHandlerCode.SCREEN_SAVER, KeyHandlerCode.EXIT_POP_UP, KeyHandlerCode.ADS];
      // check if the active state is not in that list, if it doesn't we can save the active state in the prev state
      const shouldKeepInPrev = !prevHandlers.includes(currentActive);
      // since "carousel" always saved to prevHandler we need to exclude it on player becuase we don't want to change prev on player, only when we exit it
      // const isCarouselFromPlayer = state.activeCarouselManager === "player" && currentActive === KeyHandlerCode.CAROUSEL
      const newKeyHandler = action.keyHandler === "exitPlayer" ? state.keyHandlerBeforePlayer : action.keyHandler ? action.keyHandler : currentPrev

      if (shouldKeepInPrev) {
        previousKeyHandler = currentActive;
      }
      else {
        previousKeyHandler = currentPrev;
      }

      // if action.keyHandler is empty we return to the prev keyHandler
      return {
        ...state,
        keyHandler: newKeyHandler,
        previousKeyHandler
      }
    default: return state
  }
}