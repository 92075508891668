import React, { useState, useEffect, useRef, useCallback } from 'react'
import { setKeyHandler } from 'Services/redux/app/actions';
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import useKeyHandler from 'hooks/useKeyHandler';
import useFunction from 'hooks/useFunction';

// components
import PlayerWindowTime from './PlayerWindowTime';
import CurrentTime from '../controls/CurrentTime';
import LiveLabel from 'components/videoPlayer/components/UI/LiveLabel';

// services
import { formatTimeString, Throttle } from 'utils/utiliesFunctions';
import PlayerInterface from '../../../../../Services/Player/playersCenter';
import { KeyHandlerCode } from 'data/constants';

import "./progressBar.css";

let videoForvordValue = 0, seekingTimeout;

const windowTimeElementWidth = window.innerWidth === 1280 ? 45 : 50;
let videoForvordTime = 10;
let videoForvordRound = 1;
let progressRect = { left: 0, width: 0 };

function ProgressBar (props) {

	const dispatch = useDispatch();
	const { rtl, selectedVideo } = useSelector(({ app, video }) => ({
		rtl: app.graphic.rtl,
		selectedVideo: video.selectedVideo
	}), shallowEqual);







	const [newTime, setNewTime] = useState("00:00");
	const [progressTime, setProgressTime] = useState("00:00");
	const progressWatchedRef = useRef();
	const progressRef = useRef();
	const windowTimeRef = useRef();

	useEffect(() => {
		if (progressRef.current) {
			const { width, left } = progressRef.current.getBoundingClientRect();
			progressRect.left = left;
			progressRect.width = width;
		}
	}, []);

	useEffect(() => {
		props.isActive && progressRef.current.focus();
	}, [props.isActive])

	useEffect(() => {
		const updateProgressData = Throttle(({ target }) => {
			const { currentTime, duration } = target;

			if (progressWatchedRef.current)
				progressWatchedRef.current.style.width = `${100 * currentTime / duration}%`;

			setProgressTime(formatTimeString(currentTime));
		}, 800);

		const updateProgressBar = (video) => {
			if (!video.is_live_streaming)
				PlayerInterface.addEvents([["timeupdate", updateProgressData]]);
		}

		updateProgressBar(selectedVideo);

		// Run "updateProgressBar" function again after player got destroyed due to change of the content type [video <--> audio]
		PlayerInterface.EventBus.add("VIDEO_CONTENT_TYPE_CHANGED", updateProgressBar);

		return () => {
			clearInterval(seekingTimeout);
			PlayerInterface.removeEvents([["timeupdate", updateProgressData]]);
			PlayerInterface.EventBus.remove("VIDEO_CONTENT_TYPE_CHANGED", updateProgressBar);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedVideo]);


	const seekTo = (action) => {


		clearTimeout(seekingTimeout);



		if (videoForvordRound == 6) {
			videoForvordTime = 20
		} else if (videoForvordRound == 12) {
			videoForvordTime = 30
		}

		videoForvordRound++;

		PlayerInterface.controller("pause");

		videoForvordValue += action === "rewind" ? -videoForvordTime : videoForvordTime;

		let time = PlayerInterface.playerInstance.getCurrentTime() + videoForvordValue;
		time = time < 0 ? 0 : time;
		updateProgressWhileMoving(time);



		seekingTimeout = setTimeout(() => {
			let duration = parseInt(PlayerInterface.playerInstance.getDuration());
			if (action === "rewind")
				videoForvordValue *= -1;

			time = parseInt(time) >= duration ? duration - 5 : time;

			PlayerInterface.playerInstance.seekTo(time);
			videoForvordValue = 0;
			videoForvordTime = 10;
			videoForvordRound = 1;

			PlayerInterface.playerInstance.play();

		}, 1000);
	};


	const updateProgressWhileMoving = (newTime) => {
		let progressPercent, videoStringTime;
		const stream_duration = PlayerInterface.playerInstance.getDuration()

		if (newTime >= stream_duration) { //on end video
			progressPercent = 100;
			videoStringTime = formatTimeString(stream_duration);
		} else {
			progressPercent = 100 * newTime / stream_duration;
			videoStringTime = formatTimeString(newTime);
		}

		progressWatchedRef.current.style.width = `${progressPercent.toFixed(1)}%`
		setProgressTime(videoStringTime)
	}

	const getProgressBarInSeconds = (clientX) => {
		const posInPercent = (clientX - progressRect.left) * 100 / progressRect.width;
		const getPosInPercentRelativeToDOMDirection = !rtl ? posInPercent : 100 - posInPercent;

		return getPosInPercentRelativeToDOMDirection * PlayerInterface.playerInstance.getDuration() / 100;
	}

	const progressHover = () => {
		props.setRowPosition(0);
		dispatch(setKeyHandler(KeyHandlerCode.CONTROLS));
	};

	const rewind = useCallback(Throttle(() => seekTo("rewind"), 250), []);
	const forward = useCallback(Throttle(() => seekTo("forward"), 250), []);

	useKeyHandler({
		keys: {
			right: forward,
			left: rewind
		},
		isActive: props.isActive && !props.isLiveStreaming,
	});

	useKeyHandler({
		keys: {
			forward,
			rewind
		},
		isActive: !props.isLiveStreaming
	});

	const onProgressClick = useFunction(({ clientX }) => {
		PlayerInterface.playerInstance.seekTo(getProgressBarInSeconds(clientX));
	});

	const onMouseMovedOverProgress = useFunction(({ clientX }) => {
		windowTimeRef.current.style.transform = `translateX(${clientX - progressRect.left - windowTimeElementWidth}px)`;
		setNewTime(formatTimeString(getProgressBarInSeconds(clientX)));
	});

	return (
		<>
			{ !props.isLiveStreaming &&
				<div
					className="progress_bar_container"
					onClick={ onProgressClick }
					onMouseEnter={ progressHover }
					onMouseMove={ onMouseMovedOverProgress }
					ref={ progressRef }
					aria-label="progress bar, use left or right"
					tabIndex={ -1 }
				>
					<PlayerWindowTime ref={ windowTimeRef } newTime={ newTime } />

					<div className={ `progress_bar ${props.isActive ? "active_progress_bar" : ""}` }>
						<div className="progressBar-lines">
							<div className="progressBar-total video_UI_color_unactive" />
							<div className="progressBar-watched" ref={ progressWatchedRef } />
						</div>
					</div>
				</div>
			}

			<div className="timeLabel">
				{ props.isLiveStreaming
					? <LiveLabel />
					: <CurrentTime progressTime={ progressTime } duration={ formatTimeString(PlayerInterface.action("getDuration")) } />
				}
			</div>
		</>
	)
}

export default ProgressBar

