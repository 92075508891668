const CarouselManagerTypes = Object.freeze({
  LIVE: "live",
  MULTI_CATEGORIES: "multiCategories",
  HOME: "homePage",
  PLAYER: "player",
  SEARCH: "search"
});

const KeyHandlerCode = Object.freeze({
  SEARCH_INPUT: "search",
  BASIC: "basic",
  CONTROLS: "controls",
  CAROUSEL: "carousel",
  MULTI_CATEGORIES: "categories",
  SCREEN_SAVER: "screenSaver",
  EXIT_POP_UP: "exitPopUp",
  MENU: "menu",
  GRID: "videosGrid",
  ADS: "ads",
  HUB_MENU: "hubMenu",
  SETTINGS: "settings",
  KEYBOARD: "keyboard"
});

export {
  CarouselManagerTypes,
  KeyHandlerCode
}