import React from 'react';

function Watermark ({ watermark_image }) {

	return (
		<div className="waterMark">
			<img src={watermark_image} width="100px" style={{ opacity: .8 }} alt="castify" />
		</div>
	)
}

export default React.memo(Watermark);
