import React from 'react'

const WithPopup = (Component, settings = {}) => {

  const PopupComponent = (props) => {
    return (
      <div className="withpopup_container centerlize" role="dialog">
        <Component {...props} />
      </div>
    )
  }

  return React.memo(PopupComponent);
}

export default WithPopup