const getType = (data) => {
  if (!data) return undefined;

  try {
    const parsedData = JSON.parse(data);
    if (Array.isArray(parsedData)) return "array";
    else return "object";
  }
  catch {
    // if we cant parse the data it maybe string
    return "string";
  }
}

const getLocalStorage = (key, defaultVal, functionData) => {
  const localStorageItem = localStorage.getItem(key);

  if (!localStorageItem) return defaultVal;

  try {
    if (functionData) {
      return functionData(JSON.parse(localStorageItem));
    }

    return JSON.parse(localStorageItem);
  }
  catch {
    return defaultVal
  }

};

const setLocalStorage = ({ type, value }) => {
  switch (type) {
    case "myList/add": {
      const myList = getLocalStorage("myList", {});
      updateLocalStorage("myList", {...myList, [value]: true });
      break;
    }
    case "myList/remove": {
      const myList = getLocalStorage("myList", {});
      delete myList[value];
      updateLocalStorage("myList", myList);
      break;
    }
    case "continueWatching/add": {
      const continueWatchingStorage = getLocalStorage("continueWatching", {});
      updateLocalStorage("continueWatching", { ...continueWatchingStorage, ...value })
      break;
    }
    case "continueWatching/remove": {
      const continueWatchingStorage = getLocalStorage("continueWatching", {});
      delete continueWatchingStorage[value];
      updateLocalStorage("continueWatching", continueWatchingStorage)
      break;
    }
    default: console.error(`Unknown localStorage type ${type}`);
  }

};

const updateLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value))
}

const getSessionStorage = (key, defaultVal = null) => {
  const sessionStorageItem = sessionStorage.getItem(key);
  if (!sessionStorageItem) return defaultVal;

  const type = getType(sessionStorageItem)

  return type === "string" ? sessionStorageItem : JSON.parse(sessionStorageItem);
};

const setSessionStorage = (key, value) => {
  // sessionStorage.setItem(key, JSON.stringify(value));

  // return getStorage(key);
};

export {
  getLocalStorage,
  setLocalStorage,
  getSessionStorage,
  setSessionStorage
}