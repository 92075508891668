import React, { useEffect } from 'react'
import BasicPage from './basic/BasicPage'
import Search from "./search/Search";
import MultiCategories from "./multiCategories/MultiCategories";
import Store from './store/Store';
import LiveNew from './live/LiveNew';
import { KeyHandlerCode } from 'data/constants';
import { useSelector, shallowEqual } from "react-redux";
import Hub from './hub/Hub';
import Analtyics from 'Services/analytics/globalAnaltyics';
// import Settings from './settings/Settings';
import "./Pages.css"

function PageMaster() {

	const { activePage, pageContainer } = useSelector(({ app, entities }) => ({
		activePage: app.activePage,
		pageContainer: app.activePage ? entities.pages[app.activePage.page_id]: null
	}), shallowEqual);

	useEffect(() => {
		if (activePage){
			Analtyics.sendEvent("pageVisit", {
				page_ID: activePage.page_id,
				page_title: activePage.page_title
			})
		}
	}, [activePage]);

	const displayPage = () => {
		switch (activePage.page_client_class) {
			case "about":
				if (activePage.page_path === "/about")
					return <BasicPage type="about" />
				else if (activePage.page_path === "/settings")
					return <BasicPage type="settings" />
				break
			case "store": return <Store />
			case "search": return <Search />
			case "live": return <LiveNew container={pageContainer} KeyHandlerCode={KeyHandlerCode.CAROUSEL} />
			case "multiCategories": return <MultiCategories container={pageContainer} type="multi" />
			case "hub": return <Hub container={pageContainer} kind="page" />
			// case "settings": return <Settings KeyHandlerCode={KeyHandlerCode.SETTINGS} />
			default: return "";
		}
	}

	return activePage
		? <div className="pageWarpper fullScreen">
			{displayPage()}
		</div>
		: ""
}

export default React.memo(PageMaster)