const removeKeyHandler = handler => {
	window.removeEventListener("keydown", handler);
}

const addKeyHandler = (handler, capture) => {
	window.addEventListener("keydown", handler, capture);
}

/**
 * Check if device is connected to the internet.
 * 
 * @returns {boolean} Whether the device is connected to internet.
 */
const hasInternet = () => navigator.onLine;

/**
 * Get data-* attribute value.
 * 
 * @param {HTMLElement} elem DOM element.
 * @param {string} key The name of the data attribute.
 *  
 * @returns {string} The value of the "key" attribute.
 */
const dataAttr = (elem, key) => {
	try {
		return elem.dataset[key];
	} catch (err) {
		console.log("Cannot get data attribute", err)
		return null;
	}
}

/**
 * Abbreviation large numbers.
 * 
 * @param {number} num The number to transform.
 * 
 * @returns {string} Abbreviation number.
 */
const shortAmount = (num) => {
	const splitNumber = num.toPrecision(2).split("e");
	const floorZeros = splitNumber.length === 1 ? 0 : Math.floor(Math.min(splitNumber[1].slice(1), 14) / 3)
	const shortHandNumber = Math.abs(floorZeros < 1 ? num : (num / Math.pow(10, floorZeros * 3)).toFixed(2)) // divide by power
	const finalValue = shortHandNumber + ['', 'K', 'M', 'B', 'T'][floorZeros]; // append power
	return finalValue;
}

/**
 * Transform seconds to readable form- HH:MM:SS
 * 
 * @param {number} timeInSeconds The number (in seconds) to transform.
 * 
 * @returns {string} Time format.
 */
const formatTimeString = (timeInSeconds = 0) => {

	const _time = timeInSeconds < 0 ? 0 : timeInSeconds
	const hr = ("0" + Math.floor(_time / 3600)).slice(-2);
	const min = ("0" + Math.floor(_time % 3600 / 60)).slice(-2);
	const sec = ("0" + Math.floor(_time % 3600 % 60)).slice(-2);

	return _time >= 3600 ? `${hr}:${min}:${sec}` : `${min}:${sec}`;
}

/**
* Throttle function
*
* @param {function} function to run after -delay- seconds
* @param {number} delay delay time in miliseconds
*/
const Throttle = (func, delay) => {
	let prev = 0;

	return (...args) => {
		const now = new Date().getTime();

		// If difference is greater than delay call
		// the function again.
		if ((now - prev) > delay) {
			prev = now;
			return func(...args);
		}
	}
}

export {
	removeKeyHandler,
	addKeyHandler,
	shortAmount,
	formatTimeString,
	dataAttr,
	Throttle,
	hasInternet
}