import BackspaceIcon from '@material-ui/icons/Backspace';
import SearchIcon from '@material-ui/icons/Search';
import SpaceBarIcon from '@material-ui/icons/SpaceBar';
export const data = {
  keys: [
    {
      letter: "a",
      number: "1"
    },
    {
      letter: "b",
      number: "2"
    },
    {
      letter: "c",
      number: "3"
    },
    {
      letter: "d",
      number: "&"
    },
    {
      letter: "e",
      number: "#"
    },
    {
      letter: "f",
      number: "("
    },
    {
      letter: "g",
      number: ")"
    },
    {
      letter: "h",
      number: "@"
    },
    {
      letter: "i",
      number: "!"
    },
    {
      display: BackspaceIcon,
      className: "act",
      action: "backspace",
      label: "backspace"
    },
    {
      letter: "j",
      number: "4"
    },
    {
      letter: "k",
      number: "6"
    },
    {
      letter: "l",
      number: "5"
    },
    {
      letter: "m",
      number: "\""
    },
    {
      letter: "n",
      number: ":"
    },
    {
      letter: "o",
      number: "-"
    },
    {
      letter: "p",
      number: "^"
    },
    {
      letter: "q",
      number: "["
    },
    {
      letter: "r",
      number: "]"
    },
    {
      letter: "Clear",
      number: "Clear",
      className: "act",
      action: "clear",
      label: "clear search"
    },
    {
      letter: "s",
      number: "7"
    },
    {
      letter: "t",
      number: "8"
    },
    {
      letter: "u",
      number: "9"
    },
    {
      letter: "v",
      number: "0"
    },
    {
      letter: "w",
      number: "/"
    },
    {
      letter: "x",
      number: "$",
    },
    {
      letter: "y",
      number: "%",
    },
    {
      letter: "z",
      number: "+",
    },
    {
      letter: "-",
      number: "-",
    },
    {
      letter: "#@!$",
      number: "abc",
      className: "act",
      action: "symbols",
      label: "symbols"
    },
    {
      letter: "?",
      number: "?",
    },
    {
      className: "space",
      display: SpaceBarIcon,
      action: "&nbsp;",
      label: "space"
    },
    {
      letter: ",",
      number: ","
    },
    {
      letter: ".",
      number: "."
    },
    {
      display: SearchIcon,
      className: "act",
      action: "search",
      label: "search"
    }
  ]
}