import React, { useState } from 'react';
import useLanguage from 'hooks/useLanguage';
import Sale from '../widgets/Sale';
import "./productStyle.css";
import ImageWloader from 'components/Widgets/images/ImageWloader';
import StoreLoader from 'components/Widgets/loaders/SpinnerLoader';

export default function Product(props) {
  const checkIsSale = () => {
    const item = props.item;
    if (item.item_sale_price)
      return item.item_retail_price > item.item_sale_price ? true : false
    else
      return false
  }

  const [isSale] = useState(checkIsSale);
  const getWord = useLanguage();

  const setItemPrice = () => {
    return isSale
      ? <><s className="retail">{props.item.item_retail_price}</s> &#x24; {props.item.item_sale_price}</>
      : <>&#x24; {props.item.item_retail_price}</>
  }

  return (
    <div className="productInner">
      {isSale && <Sale />}

      <div className="store-itemImage">
        <ImageWloader
          src={props.item.item_image}
          layout="cover"
          width="350"
          height="350"
          alt={props.item.item_title}
          hideImageOnFail={false}
        >
          <StoreLoader />
        </ImageWloader>
      </div>

      <div className="store-itemData">
        <h2 className="store-title cutLines">{props.item.item_title}</h2>
        <p className="store-price">{setItemPrice()}</p>
      </div>


      <div className="store-linkSection list">
        <div className="store-linkSection-text">
          {props.item.short_link_url &&
            <>
              <p>{getWord("quickShop")}</p>
              <p className="colorBlue bold">{props.item.short_link_url}</p>
            </>
          }
          <p>{getWord("orScan")}</p>
        </div>

        <div className="store-linkSection-qrImg">
          <ImageWloader
            src={props.item.qr_img}
            default={props.default}
            width="100"
            height="100"
            alt="QR"
            hideImageOnFail={true} 
            />
        </div>
      </div>

    </div>
  )
}
